import timeSlotQueries from "./timeSlotQueries";

const fields = `
  id
  name
  address
  zipcode
  city
  lat
  lng
  description
  image
  color
  max_orders
  max_products
  timeslot_safeguard
  type
`;

const fieldsWithChildren = `
  ${fields}
  timeslots {
    ${timeSlotQueries.fields}
  }
`;

const populateFields = (location) => {
  return `
    name: "${location.name}"
    address: "${location.address}"
    zipcode: "${location.zipcode}"
    city: "${location.city}"
    lat: "${location.lat}"
    lng: "${location.lng}"
    description: "${location.description}"
    color: "${location.color}"
    max_orders: ${location.max_orders}
    max_products: ${location.max_products}
    timeslot_safeguard: ${location.timeslot_safeguard}
    type: "${location.type}"     
  `;
};

const create = (location) => ({
  query: `    
    mutation {
      createLocation(
        input: {
          ${populateFields(location)}
          store_id: ${location.store_id}
        }
        ) {
          ${fields}
        }
      }    
      `,
});

const createWithImage = (location) => ({
  query: `    
    mutation ($file: Upload!) {
      createLocation(
        input: {
          ${populateFields(location)}
          store_id: ${location.store_id}
          image: $file
        }
        ) {
          ${fields}
        }
      }    
      `,
});

const update = (id, location) => ({
  query: `    
    mutation {
      updateLocation(
        id: ${id}
        input: {
          ${populateFields(location)}
        }
      ) {
        ${fields}
      }
    }    
  `,
});

const updateWithImage = (id, location) => ({
  query: `
    mutation ($file: Upload!) {
      updateLocation(
        id: ${id}
        input: {
          ${populateFields(location)}
          image: $file
        }
      ) {
        ${fields}
      }
    }
  `,
});

const remove = (id) => ({
  query: `
    mutation {
      deleteLocation (
          id: ${id}
      ) {
          id
      }
    }
  `,
});

export default {
  create,
  createWithImage,
  update,
  updateWithImage,
  remove,
  fields,
  fieldsWithChildren,
};
