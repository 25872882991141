import React, { useState } from "react";
import { RootStore } from "store";
import { ButtonGroup, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

const SplitOrdersOverview = ({
  type,
  loading,
  defaultActions,
}: {
  type: string;
  loading: boolean;
  defaultActions: any;
}) => {
  const [updating, setUpdating] = useState(false);

  const actions = {
    picker: function Actions(cell, row) {
      return row.rawStatus === "NEW" ? (
        <Link
          className="btn btn-outline-primary"
          to={"orders/" + row.id + "/print"}
        >
          Print receipt
        </Link>
      ) : (
        <></>
      );
    },
    delivery: function Actions(cell, row) {
      return row.rawStatus === "IN_PROGRESS" ? (
        <>
          <Button
            variant="success"
            onClick={async () => {
              setUpdating(true);
              row.setStatus("PICKED_UP");
              await row.update();
              setUpdating(false);
            }}
            disabled={updating}
          >
            Picked up
          </Button>
          <Button
            variant="danger"
            onClick={async () => {
              setUpdating(true);
              row.setStatus("NOT_PICKED_UP");
              await row.update();
              setUpdating(false);
            }}
            disabled={updating}
          >
            Not picked up
          </Button>
        </>
      ) : (
        <></>
      );
    },
  };

  const columns = [
    {
      dataField: "id",
      text: "#",
      sort: true,
    },
    {
      dataField: "email",
      text: "Name",
      sort: true,
    },
    {
      dataField: "totalItems",
      text: "# items",
      sort: true,
    },
    {
      dataField: "totalPriceWithDelivery",
      text: "# total",
      sort: true,
      formatter: (cell) => {
        return "€" + Number(cell).toFixed(2);
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
    {
      dataField: "action",
      text: "Actions",
      sort: false,
      formatter: function Formatter(cell, row) {
        return (
          <ButtonGroup>
            {defaultActions(cell, row)}
            {actions[type](cell, row)}
          </ButtonGroup>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  return (
    <>
      {RootStore.OrderStore?.orders?.map(() => {
        return null;
      })}
      <div>
        {RootStore.OrderStore && RootStore.OrderStore.orders.length > 0
          ? RootStore.OrderStore?.SplitTableData.map((ordersPerTimeSlot, i) => {
              return ordersPerTimeSlot.map((orders, x) => {
                return (
                  <div className="row mb-5" key={i}>
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body">
                          <h4
                            className="card-title"
                            style={{
                              color: orders[0].locationColor,
                            }}
                          >
                            {orders[0].locationName +
                              " | " +
                              orders[0].timeslot.from +
                              " - " +
                              orders[0].timeslot.to}
                          </h4>

                          <div className="row">
                            <div className="col-12">
                              <ToolkitProvider
                                keyField="id"
                                bootstrap4
                                data={orders}
                                columns={columns}
                              >
                                {(props) => (
                                  <div>
                                    <BootstrapTable
                                      defaultSorted={defaultSorted}
                                      {...props.baseProps}
                                      wrapperClasses="table-responsive"
                                    />
                                  </div>
                                )}
                              </ToolkitProvider>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              });
            })
          : "No results found"}
      </div>
    </>
  );
};

export default observer(SplitOrdersOverview);
