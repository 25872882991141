import { types } from "mobx-state-tree";
import Product, { ProductModel, ProductSnapshot } from "./models/Product";
import { productQueries } from "queries";
import { api } from "services";
import { flow } from "mobx";
import alertUtil from "util/alertUtil";
import PaginatorInfo from "./models/PaginatorInfo";

const ProductStore = types
  .model({
    products: types.array(Product),
  })
  .actions((self) => ({
    addProduct(product: ProductSnapshot) {
      if (self.products) self.products = [...self.products, product] as any;
      else self.products = [product] as any;
    },
    deleteProduct(id: string) {
      const product = self.products.find(
        (product) => product.id === id
      ) as ProductModel;
      self.products.remove(product);
    },
  }))
  .actions((self) => ({
    createProduct: flow(function* (
      product: ProductSnapshot,
      categories: number[],
      imageFile: any
    ) {
      let response;

      if (imageFile) {
        response = yield api.sendQueryWithFile(
          productQueries.createWithImage(product, categories),
          imageFile
        );
      } else {
        response = yield api.sendQuery(
          productQueries.create(product, categories)
        );
      }

      if (response.data.data && response.data.data.createProduct) {
        self.addProduct(response.data.data.createProduct);
        alertUtil.success("Product created");
      }
    }),
    removeProduct: flow(function* (id: string) {
      const response = yield api.sendQuery(productQueries.remove(id));

      if (response.data.data && response.data.data.deleteProduct) {
        self.deleteProduct(id);
        alertUtil.success("Product deleted");
        return true;
      }

      return false;
    }),
  }))
  .views((self) => ({
    get TableData() {
      return self.products?.map((product: ProductModel) => {
        return {
          id: Number(product.id),
          name: product.name,
          demand: product.demand,
          weight: product.weight,
        };
      });
    },
    get StoreTableData() {
      return self.products?.map((product: ProductModel) => {
        return {
          id: Number(product.id),
          name: product.name,
          stores: product.stores,
          demand: product.demand,
          updateStock: product.updateStock,
        };
      });
    },
  }));

export default ProductStore;
