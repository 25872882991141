import { types, SnapshotIn, Instance } from "mobx-state-tree";

const CampusClosed = types.model("CampusClosed", {
  id: types.identifier,
  date: types.string,
  campus_id: types.maybeNull(types.string),
});

export type CampusClosedSnapshot = SnapshotIn<typeof CampusClosed>;
export type CampusClosedModel = Instance<typeof CampusClosed>;
export default CampusClosed;
