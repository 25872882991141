import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Trans } from "react-i18next";
import { RootStore } from "store";
import { observer } from "mobx-react-lite";

const Sidebar = ({ routes }: { routes: any }) => {
  const location = useLocation();

  const isPathActive = (path) => {
    return location.pathname.startsWith(path);
  };

  const hasAccessTo = (name) => {
    return RootStore?.User?.hasAccessTo(name);
  };

  const menuItemsConfig = {
    dashboard: {
      displayName: "Dashboard",
      icon: "mdi-television",
    },
    orders: {
      displayName: "Orders",
      icon: "mdi-table",
    },
    picker: {
      displayName: "Picker",
      icon: "mdi-home",
    },
    delivery: {
      displayName: "Delivery",
      icon: "mdi-map-marker",
    },
    products: {
      displayName: "Products",
      icon: "mdi-cart",
    },
    users: {
      displayName: "Users",
      icon: "mdi-account",
    },
    campuses: {
      displayName: "Campuses",
      icon: "mdi-school",
    },
  };

  const renderNavItem = (viewName) => {
    if (hasAccessTo(viewName)) {
      const config = menuItemsConfig[viewName];
      let displayName = viewName;
      let icon = "mdi-arrow-right";

      if (config) {
        displayName = config.displayName;
        icon = config.icon;
      }

      return (
        <li
          key={viewName}
          className={
            isPathActive(`/${viewName}`) ? "nav-item active" : "nav-item"
          }
        >
          <Link className="nav-link" to={"/" + viewName}>
            <i className={"menu-icon mdi " + icon}></i>
            <span className="menu-title">
              <Trans>{displayName}</Trans>
            </span>
          </Link>
        </li>
      );
    }
  };

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <div className="text-center sidebar-brand-wrapper d-flex align-items-center">
        <a className="sidebar-brand brand-logo" href="/dashboard">
          <img src={require("../assets/images/logo-small.png")} alt="logo" />
        </a>
        <a className="sidebar-brand brand-logo-mini pt-3" href="/dashboard">
          <img src={require("../assets/images/logo-small.png")} alt="logo" />
        </a>
      </div>
      <ul className="nav">
        <li className="nav-item nav-profile not-navigation-link">
          <div className="nav-link">
            <div className="d-flex justify-content-between align-items-start">
              <div className="text-left">
                <p className="profile-name">
                  <Trans>{RootStore.User ? RootStore.User?.email : ""}</Trans>
                </p>
                <small className="designation text-muted text-small">
                  <Trans>
                    {RootStore.User && RootStore.User?.roles
                      ? RootStore.User?.Role
                      : ""}
                  </Trans>
                </small>
              </div>
            </div>
          </div>
        </li>
        {RootStore.User?.campus || RootStore.User?.isAdmin
          ? Object.keys(routes).map((key) => renderNavItem(key))
          : ""}
      </ul>
    </nav>
  );
};

export default observer(Sidebar);
