import React, { useState, useEffect, useCallback } from "react";
import { RootStore } from "store";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { observer } from "mobx-react-lite";
import { Button, ButtonGroup } from "react-bootstrap";
import {
  CustomModal,
  UserForm,
  ConfirmModal,
  Pagination,
  Loader,
} from "components";
import { UserSnapshot, UserModel } from "store/models/User";
import { PaginatorInfoModel } from "store/models/PaginatorInfo";
import { api } from "services";
import { userQueries } from "queries";

const UsersOverviewPage = () => {
  const paginatorInfo: PaginatorInfoModel | undefined =
    RootStore.UserStore?.paginatorInfo;

  const loadData = useCallback(async () => {
    setLoading(true);

    const response = await api.sendQuery(userQueries.list(paginatorInfo));

    if (response.data.data && response.data.data.users) {
      RootStore.setUsers(
        response.data.data.users.data,
        response.data.data.users.paginatorInfo
      );

      setLoading(false);
    }
  }, [paginatorInfo]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const [loading, setLoading] = useState(false);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState({} as UserModel);

  const onCreateSubmit = async (userData: UserSnapshot, roles: number[]) => {
    await RootStore.UserStore?.createUser(userData, roles);
    setCreateModalVisible(false);
  };

  const onEditSubmit = async (userData: UserSnapshot, roles: number[]) => {
    await selectedUser?.update(userData, roles);
    setEditModalVisible(false);
  };

  const columns = [
    {
      dataField: "email",
      text: "E-mail",
      sort: true,
    },
    {
      dataField: "roles",
      text: "Roles",
      sort: true,
    },
    {
      dataField: "campus",
      text: "Campus",
      sort: true,
    },
    {
      dataField: "action",
      text: "Actions",
      sort: false,
      formatter: function Formatter(_cell, row) {
        return (
          <ButtonGroup>
            <Button
              onClick={() => {
                setSelectedUser(row.user);
                setEditModalVisible(true);
              }}
            >
              Edit
            </Button>
            <Button
              onClick={() => {
                setSelectedUser(row.user);
                setDeleteModalVisible(true);
              }}
              className="btn btn-outline-danger"
              variant="light"
            >
              <i className="mdi mdi-delete"></i>Remove
            </Button>
          </ButtonGroup>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "email",
      order: "asc",
    },
  ];

  return (
    <>
      {RootStore.UserStore?.users?.map(() => {
        return null;
      })}
      <div>
        <div className="page-header">
          <h3 className="page-title position-relative">
            Users
            <Loader position="right" display={loading} />
          </h3>
          <Button onClick={() => setCreateModalVisible(true)}>
            + Add User
          </Button>
          <CustomModal
            visible={createModalVisible}
            onHide={() => setCreateModalVisible(false)}
            modalTitle="Add User"
          >
            <UserForm onSubmitCallback={onCreateSubmit} />
          </CustomModal>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Overview</h4>

                {RootStore.UserStore && RootStore.UserStore.users ? (
                  <div className="row">
                    <div className="col-12">
                      <ToolkitProvider
                        keyField="id"
                        bootstrap4
                        data={RootStore.UserStore?.TableData}
                        columns={columns}
                      >
                        {(props) => (
                          <div>
                            <BootstrapTable
                              defaultSorted={defaultSorted}
                              {...props.baseProps}
                              wrapperClasses="table-responsive"
                            />
                            {paginatorInfo ? (
                              <Pagination
                                paginatorInfo={paginatorInfo}
                                onPaginationChange={loadData}
                              ></Pagination>
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                      </ToolkitProvider>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        visible={editModalVisible}
        onHide={() => setEditModalVisible(false)}
        modalTitle="Edit User"
      >
        <UserForm user={selectedUser} onSubmitCallback={onEditSubmit} />
      </CustomModal>
      <ConfirmModal
        show={deleteModalVisible}
        onHide={() => setDeleteModalVisible(false)}
        onConfirm={() => {
          RootStore.UserStore?.removeUser(selectedUser.id.toString());
          setDeleteModalVisible(false);
        }}
        modalText="Are you sure you want to remove this user?"
        modalTitle={"Remove " + selectedUser.email}
      ></ConfirmModal>
    </>
  );
};

export default observer(UsersOverviewPage);
