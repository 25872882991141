import React, { useState } from "react";
import { ButtonGroup, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import ConfirmModal from "./ConfirmModal";
import CustomModal from "./CustomModal";
import { CampusClosedForm } from "./forms";
import { observer, Observer } from "mobx-react-lite";
import { CampusModel } from "store/models/Campus";
import { CampusClosedSnapshot } from "store/models/CampusClosed";
import moment from "moment";
import { RootStore } from "store";

const CampusClosedOverview = ({ campus }: { campus: CampusModel }) => {
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState({ id: "", date: "" });

  const onSubmit = async (campusClosedData: CampusClosedSnapshot) => {
    campusClosedData.campus_id = campus.id;
    campusClosedData.date = moment(campusClosedData.date, "DD-MM-yyyy").format(
      "yyyy-MM-DD"
    );

    await campus?.createCampusClosed(campusClosedData);

    setCreateModalVisible(false);
  };

  const columns = [
    {
      dataField: "date",
      text: "Date",
      sort: true,
      formatter: function Formatter(cell) {
        return <div>{moment(cell).format("DD-MM-yyyy")}</div>;
      },
    },
    {
      dataField: "action",
      text: "Actions",
      sort: false,
      formatter: function Formatter(cell, row) {
        return RootStore.User?.isAdmin ? (
          <ButtonGroup>
            <Button
              onClick={() => {
                setSelectedDate(row);
                setDeleteModalVisible(true);
              }}
              className="btn btn-outline-danger"
              variant="outline-danger"
            >
              <i className="mdi mdi-delete"></i>Remove
            </Button>
          </ButtonGroup>
        ) : (
          <></>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "date",
      order: "asc",
    },
  ];

  return (
    <>
      {campus?.closed?.map((x) => {
        return null;
      })}
      <div>
        <div className="page-header">
          <h3 className="page-title">Days closed</h3>
          {RootStore.User?.isAdmin ? (
            <>
              <Button onClick={() => setCreateModalVisible(true)}>
                + Add date
              </Button>
              <CustomModal
                visible={createModalVisible}
                onHide={() => setCreateModalVisible(false)}
                modalTitle="Add date"
              >
                <CampusClosedForm onSubmitCallback={onSubmit} />
              </CustomModal>
            </>
          ) : (
            ""
          )}
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Overview</h4>
                <div className="row">
                  <div className="col-12">
                    <Observer>
                      {() => (
                        <ToolkitProvider
                          keyField="id"
                          bootstrap4
                          data={campus?.closed}
                          columns={columns}
                        >
                          {(props) => (
                            <div>
                              <BootstrapTable
                                defaultSorted={defaultSorted}
                                {...props.baseProps}
                                wrapperClasses="table-responsive"
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      )}
                    </Observer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmModal
        show={deleteModalVisible}
        onHide={() => setDeleteModalVisible(false)}
        onConfirm={() => {
          campus.removeCampusClosed(selectedDate.id);
          setDeleteModalVisible(false);
        }}
        modalText="Are you sure you want to remove this date?"
        modalTitle={"Remove " + selectedDate.date}
      />
    </>
  );
};

export default observer(CampusClosedOverview);
