class storageUtil {
  storageUrl: string | undefined;

  constructor() {
    this.storageUrl = process.env.REACT_APP_STORAGE_URL;
  }

  public getStoragePath = (path: string) => {
    if (path.startsWith("http")) {
      return path;
    }

    return this.storageUrl + path;
  };

  public getFileName = (path: string) => {
    if (path.startsWith("http")) {
      return path;
    }

    return path.substring(path.lastIndexOf("/") + 1);
  };
}

export default new storageUtil();
