const fields = `
  id
  from
  to
  active
  day
`;

const populateFields = (timeSlot) => {
  return `
    from: "${timeSlot.from}"
    to: "${timeSlot.to}"
    active: ${timeSlot.active}
    day: ${timeSlot.day}
  `;
};

const create = (timeSlot) => ({
  query: `    
    mutation {
      createTimeSlot(
        input: {
          ${populateFields(timeSlot)}
          location_id: ${timeSlot.location_id}
        }
        ) {
          ${fields}
        }
      }    
    `,
});

const update = (id, timeSlot) => ({
  query: `    
    mutation {
      updateTimeSlot(
        id: ${id}
        input: {
          ${populateFields(timeSlot)}
        }
      ) {
        ${fields}
      }
    }    
  `,
});

const remove = (id) => ({
  query: `
    mutation {
      deleteTimeSlot (
          id: ${id}
      ) {
          id
      }
    }
  `,
});

export default {
  create,
  update,
  remove,
  fields,
};
