import alertUtil from "./alertUtil";

class errorUtil {
  private errors = {
    "login.invalid": "Invalid login details",
  };

  private default = "";

  public handleError(error: string): boolean {
    if (this.errors[error]) {
      alertUtil.error(this.errors[error]);
      return true;
    }

    alertUtil.error("An error occurred in our API");
    return false;
  }
}

export default new errorUtil();
