import locationQueries from "./locationQueries";

const fields = `
  id
  name
  address          
  image
  campus {
    id
  }
  last_pickup_time
  cartkenOpenFrom
  cartkenClosedFrom
  deleted_at
`;

const fieldsWithChildren = `
  ${fields}
  locations {
    ${locationQueries.fieldsWithChildren}
  }
`;

const populateFields = (store) => {
  return `
    name: "${store.name}"
    address: "${store.address}"
    last_pickup_time: "${store.last_pickup_time}"
    cartkenClosedFrom: "${store.cartkenClosedFrom}"
    cartkenOpenFrom: "${store.cartkenOpenFrom}"
  `;
};

const create = (store) => ({
  query: `    
    mutation {
      createStore(
        input: {
          ${populateFields(store)}
          campus_id: ${store.campus_id}
        }
        ) {
          ${fieldsWithChildren}
        }
      }    
      `,
});

const createWithImage = (store) => ({
  query: `    
    mutation ($file: Upload!) {
      createStore(
        input: {
          ${populateFields(store)}
          campus_id: ${store.campus_id}
          image: $file
        }
        ) {
          ${fieldsWithChildren}
        }
      }    
      `,
});

const update = (store) => ({
  query: `    
    mutation {
      updateStore(
        id: ${store.id}
        input: {
          ${populateFields(store)}
        }
      ) {
        ${fields}
      }
    }    
  `,
});

const updateWithImage = (store) => ({
  query: `
    mutation ($file: Upload!) {
      updateStore(
        id: ${store.id}
        input: {
          ${populateFields(store)}
          campus_id: ${store.campus_id}
          image: $file
        }
      ) {
        ${fields}
      }
    }
  `,
});

const remove = (id) => ({
  query: `
    mutation {
      deleteStore (
          id: ${id}
      ) {
          id
      }
    }
  `,
});

export default {
  create,
  createWithImage,
  update,
  updateWithImage,
  remove,
  fieldsWithChildren,
};
