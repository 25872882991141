import React, { useCallback, useEffect, useState } from "react";
import { statisticsQueries } from "queries";
import { api } from "services";
import { RootStore } from "store";
import moment from "moment";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { observer } from "mobx-react-lite";
import { Loader } from "components";

const DashboardPage = (props) => {
  const [loading, setLoading] = useState(false);

  const [selectedFromDate, setSelectedFromDate] = useState(
    moment().startOf("day").toDate()
  );

  const [selectedToDate, setSelectedToDate] = useState(
    moment(selectedFromDate).add("1", "d").toDate()
  );

  const statistics = RootStore.Statistics?.TableData;

  const loadData = useCallback(async () => {
    setLoading(true);

    const response = await api.sendQuery(
      statisticsQueries.get(selectedFromDate, selectedToDate)
    );

    if (response.data.data.stats) {
      RootStore.setStatistics(response.data.data.stats);

      setLoading(false);
    }
  }, [selectedFromDate, selectedToDate]);

  useEffect(() => {
    loadData();

    const interval = setInterval(async () => {
      loadData();
    }, 10000);
    return () => clearInterval(interval);
  }, [loadData]);

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "orders",
      text: "Orders",
      sort: true,
    },
    {
      dataField: "products",
      text: "Products",
      sort: true,
    },
    {
      dataField: "revenue",
      text: "Revenue",
      sort: true,
      formatter: function Formatter(cell, row) {
        return "€" + cell;
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  return (
    <>
      <div className="page-header">
        <h3 className="page-title position-relative">
          Dashboard
          <Loader position="right" display={loading} />
        </h3>
      </div>

      <div className="col-md-12">
        <div className="row">
          <div className="col-md-4">
            <Form.Group className="row">
              <label className="col-sm-3 col-form-label">From</label>
              <div className="col-sm-9">
                <DatePicker
                  className="form-control w-100"
                  selected={selectedFromDate}
                  onChange={(date: Date) => {
                    setSelectedFromDate(date);
                  }}
                  dateFormat="dd-MM-yyyy"
                />
              </div>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <Form.Group className="row">
              <label className="col-sm-3 col-form-label">To</label>
              <div className="col-sm-9">
                <DatePicker
                  className="form-control w-100"
                  selected={selectedToDate}
                  onChange={(date: Date) => {
                    setSelectedToDate(date);
                  }}
                  dateFormat="dd-MM-yyyy"
                />
              </div>
            </Form.Group>
          </div>
        </div>
      </div>

      {statistics ? (
        <div className="row">
          <div className="col-lg-12 col-xl-6">
            <div className="row">
              <div className="col-xl-6 col-lg-4 col-md-4 col-sm-6 col-xs-6 grid-margin stretch-card">
                <div className="card card-statistics">
                  <div className="card-body">
                    <div className="clearfix">
                      <div className="float-right">
                        <div className="fluid-container">
                          <h3 className="font-weight-medium text-right mb-0 text-dark">
                            €{statistics.revenue}
                          </h3>
                        </div>
                        <p className="mb-0 text-right text-dark w-100">
                          revenue
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-4 col-md-4 col-sm-6 col-xs-6 grid-margin stretch-card">
                <div className="card card-statistics">
                  <div className="card-body">
                    <div className="clearfix">
                      <div className="float-right">
                        <div className="fluid-container">
                          <h3 className="font-weight-medium text-right mb-0 text-dark">
                            €{statistics.avg_spending}
                          </h3>
                        </div>
                        <p className="mb-0 text-right text-dark w-100">
                          avg costs per order
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-4 col-md-4 col-sm-6 col-xs-6 grid-margin stretch-card">
                <div className="card card-statistics">
                  <div className="card-body">
                    <div className="clearfix">
                      <div className="float-right">
                        <div className="fluid-container">
                          <h3 className="font-weight-medium text-right mb-0 text-dark">
                            {statistics.demand}
                          </h3>
                        </div>
                        <p className="mb-0 text-right text-dark w-100">sales</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6 col-lg-4 col-md-4 col-sm-6 col-xs-6 grid-margin stretch-card">
                <div className="card card-statistics">
                  <div className="card-body">
                    <div className="clearfix">
                      <div className="float-right">
                        <div className="fluid-container">
                          <h3 className="font-weight-medium text-right mb-0 text-dark">
                            {statistics.notPicked}
                          </h3>
                        </div>
                        <p className="mb-0 text-right text-dark w-100">
                          not picked up
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-4 col-md-4 col-sm-6 col-xs-6 grid-margin stretch-card">
                <div className="card card-statistics">
                  <div className="card-body">
                    <div className="clearfix">
                      <div className="float-right">
                        <div className="fluid-container">
                          <h3 className="font-weight-medium text-right mb-0 text-dark">
                            {statistics.picked}
                          </h3>
                        </div>
                        <p className="mb-0 text-right text-dark w-100">
                          picked up
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-4 col-md-4 col-sm-6 col-xs-6 grid-margin stretch-card">
                <div className="card card-statistics">
                  <div className="card-body">
                    <div className="clearfix">
                      <div className="float-right">
                        <div className="fluid-container">
                          <h3 className="font-weight-medium text-right mb-0 text-dark">
                            {statistics.rating}
                          </h3>
                        </div>
                        <p className="mb-0 text-right text-dark w-100">
                          rating
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-xl-6">
            <div className="card">
              <div className="card-body">
                <ToolkitProvider
                  keyField="id"
                  bootstrap4
                  data={statistics.locations}
                  columns={columns}
                >
                  {(props) => (
                    <BootstrapTable
                      defaultSorted={defaultSorted}
                      {...props.baseProps}
                      wrapperClasses="table-responsive"
                    />
                  )}
                </ToolkitProvider>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default observer(DashboardPage);
