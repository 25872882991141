import { types, Instance, applySnapshot, destroy } from "mobx-state-tree";
import CampusStore from "./CampusStore";
import OrderStore from "./OrderStore";
import { OrderSnapshot } from "./models/Order";
import UserStore from "./UserStore";
import User, { UserSnapshot } from "./models/User";
import ProductStore from "./ProductStore";
import { ProductSnapshot } from "./models/Product";
import { PaginatorInfoSnapshot } from "./models/PaginatorInfo";
import transformer from "util/transformer";
import Statistics, { StatisticsSnapshot } from "./models/Statistics";
import { CampusSnapshot } from "./models/Campus";

const RootStoreModel = types
  .model({
    User: types.maybeNull(User),
    Statistics: types.maybeNull(Statistics),
    OrderStore: types.maybeNull(OrderStore),
    CampusStore: types.maybeNull(CampusStore),
    ProductStore: types.maybeNull(ProductStore),
    UserStore: types.maybeNull(UserStore),
    isAuthenticated: types.maybe(types.boolean),
    isLoaded: types.maybe(types.boolean),
  })
  .actions((self) => ({
    reset: () => {
      self.isAuthenticated = false;
      self.isLoaded = false;
      self.User && destroy(self.User);
      self.Statistics && destroy(self.Statistics);
      self.OrderStore && destroy(self.OrderStore);
      self.CampusStore && destroy(self.CampusStore);
      self.ProductStore && destroy(self.ProductStore);
      self.UserStore && destroy(self.UserStore);
    },
    setIsAuthenticated: (isAuthenticated: boolean) => {
      self.isAuthenticated = isAuthenticated;
    },
    setIsLoaded: (isLoaded: boolean) => {
      self.isLoaded = isLoaded;
    },
    setProfile: (profile: UserSnapshot) => {
      profile = transformer.user(profile);
      self.User = User.create(profile);
    },
    setCampuses: (campuses: CampusSnapshot[]) => {
      self.CampusStore = CampusStore.create({ campuses });
    },
    setOrders: (
      orders: OrderSnapshot[],
      paginatorInfo: PaginatorInfoSnapshot
    ) => {
      if (self.OrderStore) {
        applySnapshot(self.OrderStore, { orders, paginatorInfo });
        return true;
      }

      self.OrderStore = OrderStore.create({ orders, paginatorInfo });
    },
    setProducts: (products: ProductSnapshot[]) => {
      if (self.ProductStore) {
        applySnapshot(self.ProductStore, { products });
        return true;
      }
      self.ProductStore = ProductStore.create({ products });
    },
    setUsers: (users: UserSnapshot[], paginatorInfo: PaginatorInfoSnapshot) => {
      users = transformer.users(users);

      if (self.UserStore) {
        applySnapshot(self.UserStore, { users, paginatorInfo });
        return true;
      }

      self.UserStore = UserStore.create({ users, paginatorInfo });
    },
    setStatistics: (statistics: StatisticsSnapshot) => {
      if (self.Statistics) {
        applySnapshot(self.Statistics, statistics);
        return true;
      }
      self.Statistics = Statistics.create(statistics);
    },
  }));

export type RootStoreInstance = Instance<typeof RootStoreModel>;

export default RootStoreModel.create({
  CampusStore: { campuses: [] },
});
