class Transformer {
  public users(users) {
    return users.map((user) => this.user(user));
  }

  public user(user) {
    return {
      ...user,
      campus: user.campus?.id,
    };
  }
}
export default new Transformer();
