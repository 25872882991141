import { types, SnapshotIn, Instance } from "mobx-state-tree";
import Product from "./Product";

const OrderItem = types.model("OrderItem", {
  id: types.identifier,
  product: Product,
  quantity: types.number,
});

export type OrderItemSnapshot = SnapshotIn<typeof OrderItem>;
export type OrderItemModel = Instance<typeof OrderItem>;
export default OrderItem;
