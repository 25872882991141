import { types } from "mobx-state-tree";
import User, { UserModel, UserSnapshot } from "./models/User";
import { flow } from "mobx";
import { userQueries } from "queries";
import { api } from "services";
import alertUtil from "util/alertUtil";
import transformer from "util/transformer";
import PaginatorInfo from "./models/PaginatorInfo";

const UserStore = types
  .model({
    users: types.array(User),
    paginatorInfo: PaginatorInfo,
  })
  .actions((self) => ({
    addUser(user: UserSnapshot) {
      user = transformer.user(user);
      if (self.users) self.users = [...self.users, user] as any;
      else self.users = [user] as any;
    },
    deleteUser(id: string) {
      const user = self.users.find((user) => user.id === id);

      if (user) self.users.remove(user);
    },
  }))
  .actions((self) => ({
    createUser: flow(function* (user: UserSnapshot, roles: number[]) {
      const response = yield api.sendQuery(userQueries.create(user, roles));

      if (response.data.data && response.data.data.createUser) {
        self.addUser(response.data.data.createUser);
        alertUtil.success("User created");
      }
    }),
    removeUser: flow(function* (id: string) {
      const response = yield api.sendQuery(userQueries.remove(id));
      if (response.data.data && response.data.data.deleteUser) {
        self.deleteUser(id);
        alertUtil.success("User deleted");
      }
    }),
  }))
  .views((self) => ({
    get TableData() {
      return self.users.map((user: UserModel) => {
        return {
          id: Number(user.id),
          email: user.email,
          roles: user.roles.map((role) => role.FormattedRole).join(", "),
          campus: user.campus ? user.campus.name : "-",
          user: user,
        };
      });
    },
  }));

export default UserStore;
