import { types, flow, SnapshotIn, Instance } from "mobx-state-tree";
import { api } from "services";
import { storeQueries, locationQueries } from "queries";
import Location, { LocationModel, LocationSnapshot } from "./Location";
import { AxiosResponse } from "axios";
import alertUtil from "util/alertUtil";

const Store = types
  .model("Store", {
    id: types.identifier,
    name: types.string,
    image: types.maybeNull(types.string),
    address: types.maybeNull(types.string),
    locations: types.array(Location),
    last_pickup_time: types.maybeNull(types.string),
    cartkenOpenFrom: types.maybe(types.string),
    cartkenClosedFrom: types.maybe(types.string),
    campus_id: types.maybeNull(types.string),
    campus: types.maybeNull(
      types.model("StoreCampus", {
        id: types.string,
      })
    ),
    pivot: types.maybe(
      types.model("Pivot", {
        inStock: types.boolean,
      })
    ),
    deleted_at: types.maybeNull(types.string),
  })
  .actions((self) => ({
    deleteLocation(id: string) {
      if (self.locations) {
        const location = self.locations.find(
          (location) => location.id === id
        ) as LocationModel;
        self.locations.remove(location);
      }
    },
    addLocation(location: LocationModel) {
      if (self.locations) self.locations = [...self.locations, location] as any;
      else self.locations = [location] as any;
    },
  }))
  .actions((self) => ({
    update: flow(function* (store: any, file: any = undefined) {
      let query, response;

      if (file) {
        query = storeQueries.updateWithImage(store);
        response = yield api.sendQueryWithFile(query, file);
      } else {
        query = storeQueries.update(store);
        response = yield api.sendQuery(query);
      }

      if (response.data.data && response.data.data.updateStore) {
        const data = response.data.data.updateStore;
        self.name = data.name;
        self.address = data.address;
        self.image = data.image;

        alertUtil.success("Store updated");
      }
    }),

    removeLocation: flow(function* (id: string) {
      const response = yield api.sendQuery(locationQueries.remove(id));
      if (response.data.data && response.data.data.deleteLocation) {
        self.deleteLocation(id);
        alertUtil.success("Location deleted");
        return true;
      }

      return false;
    }),

    createLocation: flow(function* (
      location: LocationSnapshot,
      file: any = undefined
    ) {
      let query, response: AxiosResponse;

      if (file) {
        query = locationQueries.createWithImage(location);
        response = yield api.sendQueryWithFile(query, file);
      } else {
        query = locationQueries.create(location);
        response = yield api.sendQuery(query);
      }

      if (response.data.data && response.data.data.createLocation) {
        self.addLocation(response.data.data.createLocation);
        alertUtil.success("Location created");
      }
    }),
  }))
  .views(() => ({}));

export type StoreSnapshot = SnapshotIn<typeof Store>;
export type StoreModel = Instance<typeof Store>;
export default Store;
