import React, { useState } from "react";
import { RootStore } from "store";
import { Link } from "react-router-dom";
import {
  StoresOverview,
  CampusForm,
  ConfirmModal,
  CampusClosedOverview,
} from "components";
import { observer } from "mobx-react-lite";
import { CampusSnapshot } from "store/models/Campus";

const CampusDetailPage = ({
  history,
  match,
}: {
  history;
  match: { params };
}) => {
  const campus = RootStore.CampusStore?.campuses.find(
    (campus) => campus.id === match.params.campusId
  );

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const onSubmit = async (campusData: CampusSnapshot, imageFile) => {
    await campus?.update(campusData, imageFile);
  };

  return (
    <>
      <div>
        <div className="page-header">
          <h3 className="page-title">{campus?.name}</h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/campuses">Campuses</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {campus?.name}
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-6 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Campus details</h4>
                <CampusForm
                  onSubmitCallback={onSubmit}
                  campus={campus}
                  onRemoveClick={() => {
                    setDeleteModalVisible(true);
                  }}
                ></CampusForm>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-lg-12">
            {campus ? <StoresOverview campus={campus} /> : ""}
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            {campus ? <CampusClosedOverview campus={campus} /> : ""}
          </div>
        </div>
      </div>

      <ConfirmModal
        show={deleteModalVisible}
        onHide={() => setDeleteModalVisible(false)}
        onConfirm={async () => {
          if (campus && campus.id) {
            const result = await RootStore.CampusStore?.remove(campus.id);
            setDeleteModalVisible(false);

            if (result) return history.push("/campuses");
          }
        }}
        modalText="Are you sure you want to remove this campus?"
        modalTitle={"Remove " + campus?.name}
      />
    </>
  );
};

export default observer(CampusDetailPage);
