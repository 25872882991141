import React, { useEffect, useCallback, useState } from "react";
import { OrderDetailModal } from "components";
import { api } from "services";
import { orderQueries } from "queries";
import Order, { OrderModel } from "store/models/Order";

const OrderPrintPage = ({ match }) => {
  const [order, setOrder] = useState<OrderModel | undefined>();

  const getOrder = useCallback(async () => {
    const response = await api.sendQuery(
      orderQueries.single(match.params.orderId)
    );

    if (response.data.data) setOrder(Order.create(response.data.data.order));
  }, [match.params.orderId]);

  useEffect(() => {
    console.log(order);
    if (!order) {
      getOrder();
    } else {
      window.print();
    }
  }, [getOrder, order]);

  return order ? (
    <OrderDetailModal
      order={order.TableData}
      printMode={true}
    ></OrderDetailModal>
  ) : (
    <></>
  );
};

export default OrderPrintPage;
