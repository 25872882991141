import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import storageUtil from "util/storageUtil";
import { LocationModel, LocationSnapshot } from "store/models/Location";
import { RootStore } from "store";
import Loader from "components/Loader";

const LocationForm = ({
  onSubmitCallback,
  location = undefined,
  onRemoveClick = undefined,
}: {
  onSubmitCallback: (location: LocationSnapshot, imageFile) => Promise<void>;
  location?: LocationModel;
  onRemoveClick?: () => void;
}) => {
  const [storeFile, setStoreFile] = useState<any>();
  const [loading, setLoading] = useState(false);

  const onFileChange = (event) => {
    setStoreFile(event.target.files[0]);
  };

  const onSubmit = async (event) => {
    setLoading(true);

    event.preventDefault();

    var type = "";

    if (event.target[13].checked) {
      type = "pickup";
    } else if (event.target[14].checked) {
      type = "delivery";
    } else {
      type = "cartken";
    }

    const locationData: LocationSnapshot = {
      id: location?.id ?? "",
      name: event.target[1].value,
      address: event.target[2].value,
      zipcode: event.target[3].value,
      city: event.target[4].value,
      lat: event.target[5].value,
      lng: event.target[6].value,
      description: event.target[7].value,
      color: event.target[9].value,
      max_orders: event.target[10].value,
      max_products: event.target[11].value,
      timeslot_safeguard: event.target[12].value,
      type: type,
    };

    const image = event.target[8].files[0];

    await onSubmitCallback(locationData, image);

    setLoading(false);
  };

  return (
    <Form onSubmit={onSubmit}>
      <fieldset disabled={!RootStore.User?.isAdmin}>
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-4">
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="card-title">Location details</h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <Form.Group>
                        <label htmlFor="name">Name *</label>
                        <Form.Control
                          type="text"
                          id="name"
                          placeholder="Name"
                          defaultValue={location?.name}
                          required
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <Form.Group>
                        <label htmlFor="address">Street + housenumber *</label>
                        <Form.Control
                          type="text"
                          id="address"
                          placeholder="Address"
                          defaultValue={location?.address?.toString()}
                          required
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <Form.Group>
                        <label htmlFor="zipcode">Zipcode *</label>
                        <Form.Control
                          type="text"
                          id="zipcode"
                          placeholder="Zipcode"
                          defaultValue={location?.zipcode?.toString()}
                          required
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <Form.Group>
                        <label htmlFor="city">City *</label>
                        <Form.Control
                          type="text"
                          id="city"
                          placeholder="City"
                          defaultValue={location?.city?.toString()}
                          required
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group>
                        <label htmlFor="lat">Latitude *</label>
                        <Form.Control
                          type="text"
                          id="lat"
                          placeholder="Latitude"
                          defaultValue={location?.lat?.toString()}
                          required
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group>
                        <label htmlFor="lng">Longitude *</label>
                        <Form.Control
                          type="text"
                          id="lng"
                          placeholder="Longitude"
                          defaultValue={location?.lng?.toString()}
                          required
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <Form.Group>
                        <label htmlFor="description">Description</label>
                        <Form.Control
                          type="text"
                          id="description"
                          placeholder="Description"
                          defaultValue={location?.description?.toString()}
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <Form.Group>
                        <label>Image *</label>
                        <div className="custom-file">
                          <Form.Control
                            type="file"
                            className="form-control visibility-hidden"
                            id="locationImage"
                            onChange={onFileChange}
                            required={!!!location}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="locationImage"
                          >
                            {storeFile
                              ? storeFile.name
                              : location?.image
                              ? storageUtil.getFileName(location.image)
                              : "Choose image"}
                          </label>
                        </div>
                        {storeFile ? (
                          <img
                            className="img-lg mt-2"
                            alt="Store"
                            src={URL.createObjectURL(storeFile)}
                          ></img>
                        ) : location?.image ? (
                          <img
                            className="img-lg mt-2"
                            alt="Store"
                            src={storageUtil.getStoragePath(location.image)}
                          ></img>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="card-title">Options</h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <Form.Group>
                        <label htmlFor="color">Color</label>
                        <Form.Control
                          type="text"
                          id="color"
                          placeholder="#"
                          defaultValue={location?.color?.toString()}
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <Form.Group>
                        <label htmlFor="max_orders">
                          Maximum orders per timeslot
                        </label>
                        <Form.Control
                          type="number"
                          id="max_orders"
                          placeholder="Maximum orders per timeslot"
                          defaultValue={
                            location?.max_orders ? location?.max_orders : 0
                          }
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <Form.Group>
                        <label htmlFor="max_products">
                          Maximum products per order
                        </label>
                        <Form.Control
                          type="number"
                          id="max_products"
                          placeholder="Maximum products per order"
                          defaultValue={
                            location?.max_products ? location?.max_products : 0
                          }
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <Form.Group>
                        <label htmlFor="timeslot_safeguard">
                          Closes x minutes before the start
                        </label>
                        <Form.Control
                          type="number"
                          id="timeslot_safeguard"
                          placeholder="Closes x minutes before the start"
                          defaultValue={
                            location?.timeslot_safeguard
                              ? location?.timeslot_safeguard
                              : 0
                          }
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="card-title">Type</h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <Form.Group>
                        <Form.Check
                          type="radio"
                          id={`type-pickup`}
                          label={`Pickup`}
                          name="type"
                          defaultChecked={location?.type === "pickup"}
                        />
                        <Form.Check
                          type="radio"
                          id={`type-delivery`}
                          label={`Delivery`}
                          name="type"
                          defaultChecked={location?.type === "delivery"}
                        />
                        <Form.Check
                          type="radio"
                          id={`type-cartken`}
                          label={`Cartken`}
                          name="type"
                          defaultChecked={location?.type === "cartken"}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <Button
                    type="submit"
                    className="btn btn-primary mr-2 position-relative"
                  >
                    <Loader
                      position={location ? "left" : "right"}
                      display={loading}
                    ></Loader>
                    {location ? "Save" : "Add"}
                  </Button>

                  {location ? (
                    <Button variant="danger" onClick={onRemoveClick}>
                      Remove
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </Form>
  );
};

export default LocationForm;
