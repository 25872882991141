import { types, Instance } from "mobx-state-tree";

const UserRole = types
  .model("UserRole", {
    id: types.identifier,
    name: types.string,
  })
  .views((self) => ({
    get FormattedRole() {
      return self.name.substring(0, 1).toUpperCase() + self.name.substring(1);
    },
  }));

export type UserRoleModel = Instance<typeof UserRole>;
export default UserRole;
