import { types, flow, SnapshotIn, Instance } from "mobx-state-tree";
import OrderItem from "./OrderItem";
import Location from "./Location";
import TimeSlot from "./TimeSlot";
import User from "./User";
import { orderQueries } from "queries";
import { api } from "services";
import alertUtil from "util/alertUtil";
import OrderStatuses from "./OrderStatuses";

const Order = types
  .model("Order", {
    id: types.identifier,
    items: types.array(OrderItem),
    status: types.enumeration(Object.keys(OrderStatuses)),
    location: Location,
    timeslot: TimeSlot,
    pickUpPinCode: types.integer,
    user: types.maybeNull(User),
    created_at: types.string,
    updated_at: types.string,
    paid_at: types.maybeNull(types.string),
    log: types.maybeNull(
      types.array(
        types.model("OrderLog", {
          msg: types.string,
          created_at: types.string,
        })
      )
    ),
  })
  .actions((self) => ({
    setStatus: (status: string) => {
      self.status = status;
    },
    update: flow(function* () {
      const response = yield api.sendQuery(orderQueries.update(self));

      if (response.data.data && response.data.data.updateOrder) {
        const data = response.data.data.updateOrder;
        self.status = data.status;

        alertUtil.success("Status updated");
      }
    }),
  }))
  .views((self) => ({
    get IsDeliveryOrder() {
      return self.location.type === "delivery" ? true : false;
    },
    get TotalItems() {
      return Number(self.items.reduce((a, b) => a + b.quantity, 0));
    },
    get TotalPrice() {
      return Number(
        self.items.reduce((a, b) => a + b.quantity * b.product.price, 0)
      );
    },
    get DeliveryPrice() {
      return Number(this.IsDeliveryOrder && this.TotalPrice <= 10 ? 1 : 0);
    },
    get TotalPriceWithDelivery() {
      return Number(this.TotalPrice + this.DeliveryPrice);
    },
    get TableData() {
      return {
        id: Number(self.id),
        timeslot: self.timeslot,
        email: self.user?.email,
        status: OrderStatuses[self.status],
        rawStatus: self.status,
        pickUpPinCode: self.pickUpPinCode,
        isDeliveryOrder: this.IsDeliveryOrder,
        totalPrice: this.TotalPrice,
        deliveryPrice: this.DeliveryPrice,
        totalPriceWithDelivery: this.TotalPriceWithDelivery,
        totalItems: this.TotalItems,
        log: self.log,
        locationName: self.location.name,
        locationColor: self.location.color,
        items: self.items,
        created_at: self.created_at,
        paid_at: self.paid_at,
        setStatus: self.setStatus,
        update: self.update,
      };
    },
  }));

export type OrderSnapshot = SnapshotIn<typeof Order>;
export type OrderModel = Instance<typeof Order>;

export default Order;
