import React, { useEffect, useState, useCallback } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { StoreModel } from "store/models/Store";
import { observer } from "mobx-react-lite";
import { Form, Button } from "react-bootstrap";
import { ProductModel, ProductSnapshot } from "store/models/Product";
import { RootStore } from "store";
import { productQueries } from "queries";
import { api } from "services";
import { ProductForm } from "./forms";
import CustomModal from "./CustomModal";
import { Link } from "react-router-dom";
import Loader from "./Loader";

const ProductsOverview = ({ store = undefined }: { store?: StoreModel }) => {
  const [loading, setLoading] = useState(false);
  const [createModalVisible, setCreateModalVisible] = useState(false);

  const loadData = useCallback(async () => {
    setLoading(true);

    const response = await api.sendQuery(productQueries.all());

    if (response.data.data && response.data.data.products) {
      RootStore.setProducts(response.data.data.products);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onInStockChange = async (checked: boolean, product: ProductModel) => {
    if (store) {
      await product.updateStock(product.id, store.id, checked);
    }
  };

  const getProductInStock = (product) => {
    if (store) {
      const ProductStore = product.stores.find((s) => s.id === store.id);
      return !!ProductStore?.pivot.inStock;
    }
  };

  const setOrder = async (product: ProductModel, weight: number) => {
    const productItem = RootStore.ProductStore?.products.find(
      (p) => p.id === product.id.toString()
    );

    await productItem?.updateWeight(weight);
    loadData();
  };

  const columns = [
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "demand",
      text: "Sales",
    },
    {
      dataField: "action",
      text: "Actions",
      sort: false,
      formatter: function Formatter(cell, row) {
        return (
          <>
            {store ? (
              <span className="position-relative">
                <Form.Check
                  type="switch"
                  id={"inStock" + row.id}
                  label="In stock"
                  defaultChecked={getProductInStock(row)}
                  className="position-relative"
                  onClick={(e) => onInStockChange(e.target.checked, row)}
                ></Form.Check>
              </span>
            ) : (
              <>
                <Link
                  className="btn btn-outline-primary"
                  to={"products/" + row.id}
                >
                  <i className="mdi mdi-eye-outline text-primary"></i>View
                </Link>

                <div
                  className="ml-1 btn btn-outline-primary"
                  onClick={() => setOrder(row, 1)}
                >
                  <i className="mr-0 mdi mdi-menu-down text-primary"></i>
                </div>

                {row.weight > 0 && (
                  <div
                    className="ml-1 btn btn-outline-primary"
                    onClick={() => setOrder(row, -1)}
                  >
                    <i className="mr-0 mdi mdi-menu-up text-primary"></i>
                  </div>
                )}
              </>
            )}
          </>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  const onSubmit = async (
    productData: ProductSnapshot,
    categories: number[],
    imageFile: any
  ) => {
    await RootStore.ProductStore?.createProduct(
      productData,
      categories,
      imageFile
    );
    setCreateModalVisible(false);
  };

  return (
    <>
      {RootStore.ProductStore?.products?.map((x) => {
        return null;
      })}

      <div>
        <div className="page-header">
          <h3 className="page-title position-relative">
            Products
            <Loader position="right" display={loading} />
          </h3>

          {!store ? (
            <>
              <Button onClick={() => setCreateModalVisible(true)}>
                + Add Product
              </Button>
              <CustomModal
                visible={createModalVisible}
                onHide={() => setCreateModalVisible(false)}
                modalTitle="Add Product"
                size="lg"
              >
                <ProductForm onSubmitCallback={onSubmit} />
              </CustomModal>
            </>
          ) : (
            ""
          )}
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Overview</h4>

                <div className="row">
                  <div className="col-12">
                    {RootStore.ProductStore &&
                    RootStore.ProductStore.products.length > 0 ? (
                      <ToolkitProvider
                        keyField="id"
                        bootstrap4
                        data={
                          store
                            ? RootStore.ProductStore?.StoreTableData
                            : RootStore.ProductStore?.TableData.sort((a, b) =>
                                a.weight > b.weight ? 1 : -1
                              )
                        }
                        columns={columns}
                      >
                        {(props) => (
                          <div>
                            <BootstrapTable
                              {...props.baseProps}
                              wrapperClasses="table-responsive"
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(ProductsOverview);
