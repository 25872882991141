import moment from "moment";

const fields = `
  revenue
  avg_spending
  demand
  notPicked
  picked
  orders
  rating
  locations {
    name
    orders
    products
    revenue
  }
`;

const get = (
  from = moment().subtract(10, "d").toDate(),
  to = moment().add(1, "d").toDate()
) => {
  from = moment(from).format("yyyy-MM-DD");
  to = moment(to).format("yyyy-MM-DD");

  return {
    query: `
    {
      stats (
        period: {
          from: "${from}"
          to: "${to}"
        }
      ) {
        ${fields}
      }
    }
    `,
  };
};

export default {
  get,
};
