import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { ButtonGroup, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CustomModal, CampusForm, ConfirmModal } from "components";
import { observer, Observer } from "mobx-react-lite";
import { RootStore } from "store";
import { CampusSnapshot } from "store/models/Campus";

const CampusOverviewPage = () => {
  const campuses = RootStore.CampusStore?.campuses;

  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedCampus, setSelectedCampus] = useState({ id: "", name: "" });

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "action",
      text: "Actions",
      sort: false,
      formatter: function Formatter(cell, row) {
        return (
          <div>
            <ButtonGroup>
              <Link
                className="btn btn-outline-primary"
                to={"campuses/" + row.id}
              >
                <i className="mdi mdi-eye-outline text-primary"></i>View
              </Link>

              {/* {RootStore.User?.isAdmin ? (
                <Button
                  onClick={() => {
                    setSelectedCampus(row);
                    setDeleteModalVisible(true);
                  }}
                  variant="outline-danger"
                >
                  <i className="mdi mdi-delete"></i>Remove
                </Button>
              ) : (
                <></>
              )} */}
            </ButtonGroup>
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  const onSubmit = async (campusData: CampusSnapshot, imageFile) => {
    await RootStore.CampusStore?.create(campusData, imageFile);
    setCreateModalVisible(false);
  };

  return (
    <>
      {campuses?.map(() => {
        return null;
      })}
      <div>
        <div className="page-header">
          <h3 className="page-title">Campuses</h3>
          {RootStore.User?.isAdmin ? (
            <>
              <Button onClick={() => setCreateModalVisible(true)}>
                + Add Campus
              </Button>
              <CustomModal
                visible={createModalVisible}
                onHide={() => setCreateModalVisible(false)}
                modalTitle="Add Campus"
              >
                <CampusForm onSubmitCallback={onSubmit} />
              </CustomModal>
            </>
          ) : (
            ""
          )}
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Overview</h4>
                <div className="row">
                  <div className="col-12">
                    <Observer>
                      {() => (
                        <ToolkitProvider
                          keyField="id"
                          bootstrap4
                          data={campuses}
                          columns={columns}
                        >
                          {(props) => (
                            <div>
                              <BootstrapTable
                                defaultSorted={defaultSorted}
                                {...props.baseProps}
                                wrapperClasses="table-responsive"
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      )}
                    </Observer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConfirmModal
        show={deleteModalVisible}
        onHide={() => setDeleteModalVisible(false)}
        onConfirm={() => {
          RootStore.CampusStore?.remove(selectedCampus.id);
          setDeleteModalVisible(false);
        }}
        modalText="Are you sure you want to remove this campus?"
        modalTitle={"Remove " + selectedCampus.name}
      ></ConfirmModal>
    </>
  );
};

export default observer(CampusOverviewPage);
