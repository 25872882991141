const fields = `
  id
  email
  roles {
    id
    name
  }
  campus {
    id
    name
  }
`;

const me = () => ({
  query: `
    {
      me{
        ${fields}
      }
    }
    `,
});

const list = (paginatorInfo = { currentPage: 1, perPage: 10 }) => ({
  query: `
  {
    users(first: ${paginatorInfo.perPage}, page: ${paginatorInfo.currentPage}) {
      data {
        ${fields}
      }
      paginatorInfo {
        currentPage
        lastPage
        perPage
      }
    }
  }
  `,
});

const create = (user, roles) => ({
  query: `
    mutation {
      createUser(input: {
        email: "${user.email}"
        password: "${user.password}"
        roles: {
          sync: ${JSON.stringify(roles)}
        }
        campus_id: ${user.campus_id ? user.campus_id : null}
      }) {
        ${fields}
      }
    }
  `,
});

const update = (user, roles) => {
  let query = `
  mutation {
    updateUser(
      id: ${user.id}, 
      input: {
        roles: {
          sync: ${JSON.stringify(roles)}
        }
        campus_id: ${user.campus_id ? user.campus_id : null}
      }) {
        ${fields}
      }
    }`;

  return { query };
};

const remove = (userId) => ({
  query: `
    mutation {
      deleteUser(
        id: ${userId}
      ) {
        id
      }
    }
  `,
});

export default {
  me,
  list,
  create,
  update,
  remove,
};
