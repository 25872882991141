import { types, flow, SnapshotIn, Instance } from "mobx-state-tree";
import { api } from "services";
import Store, { StoreModel, StoreSnapshot } from "./Store";
import { campusQueries, storeQueries, campusClosedQueries } from "queries";
import { AxiosResponse } from "axios";
import CampusClosed, {
  CampusClosedSnapshot,
  CampusClosedModel,
} from "./CampusClosed";
import alertUtil from "util/alertUtil";

const Campus = types
  .model("Campus", {
    id: types.identifier,
    name: types.string,
    image: types.maybeNull(types.string),
    address: types.maybeNull(types.string),
    stores: types.array(Store),
    closed: types.array(CampusClosed),
  })
  .actions((self) => ({
    addStore(store: StoreSnapshot) {
      if (self.stores) self.stores = [...self.stores, store] as any;
      else self.stores = [store] as any;
    },

    deleteStore(id: string) {
      if (self.stores) {
        const store = self.stores.find(
          (store) => store.id === id
        ) as StoreModel;
        self.stores.remove(store);
      }
    },

    addCampusClosed(campusClosed: CampusClosedSnapshot) {
      if (self.closed) self.closed = [...self.closed, campusClosed] as any;
      else self.closed = [campusClosed] as any;
    },

    deleteCampusClosed(id: string) {
      if (self.closed) {
        const campusedClosed = self.closed.find(
          (campusedClosed) => campusedClosed.id === id
        ) as CampusClosedModel;
        self.closed.remove(campusedClosed);
      }
    },
  }))
  .actions((self) => ({
    update: flow(function* (campus: any, file: any = undefined) {
      let query, response;

      if (file) {
        query = campusQueries.updateWithImage(campus);
        response = yield api.sendQueryWithFile(query, file);
      } else {
        query = campusQueries.update(campus);
        response = yield api.sendQuery(query);
      }

      if (response.data.data && response.data.data.updateCampus) {
        const data = response.data.data.updateCampus;
        self.name = data.name;
        self.address = data.address;
        self.image = data.image;

        alertUtil.success("Campus updated");
      }
    }),

    createStore: flow(function* (store: StoreSnapshot, file: any = undefined) {
      let query, response: AxiosResponse;

      if (file) {
        query = storeQueries.createWithImage(store);
        response = yield api.sendQueryWithFile(query, file);
      } else {
        query = storeQueries.create(store);
        response = yield api.sendQuery(query);
      }

      if (response.data.data && response.data.data.createStore) {
        self.addStore(response.data.data.createStore);
        alertUtil.success("Store created");
      }
    }),

    removeStore: flow(function* (id: string) {
      const response = yield api.sendQuery(storeQueries.remove(id));

      if (response.data.data && response.data.data.deleteStore) {
        self.deleteStore(id);
        alertUtil.success("Store removed");
        return true;
      }

      return false;
    }),

    createCampusClosed: flow(function* (campusClosed: CampusClosedSnapshot) {
      const query = campusClosedQueries.create(campusClosed);
      const response = yield api.sendQuery(query);

      if (response.data.data && response.data.data.updateCampus) {
        self.closed = response.data.data.updateCampus.closed;
        alertUtil.success("Closed date added");
      }
    }),

    removeCampusClosed: flow(function* (id: string) {
      const response = yield api.sendQuery(
        campusClosedQueries.remove(id, self.id)
      );

      if (response.data.data && response.data.data.updateCampus) {
        self.deleteCampusClosed(id);
        alertUtil.success("Closed date removed");
      }
    }),
  }));

export default Campus;

export type CampusSnapshot = SnapshotIn<typeof Campus>;
export type CampusModel = Instance<typeof Campus>;
