import React, { useEffect } from "react";
import {
  LoginPage,
  DashboardPage,
  CampusOverviewPage,
  CampusDetailPage,
  StoreDetailPage,
  LocationDetailPage,
  OrdersOverviewPage,
  UsersOverviewPage,
  ProductDetailPage,
  ForgotPassword,
  ResetPassword,
} from "pages";
import { Layout } from "shared";
import { observer } from "mobx-react-lite";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import { useStores } from "store";

import "./App.scss";
import { api } from "services";
import { userQueries, campusQueries } from "queries";
import { ProductsOverview } from "components";
import OrderPrintPage from "pages/Orders/OrderPrintPage";
import { NoCampusSelected } from "pages/Error";

const App = () => {
  const RootStore = useStores();

  useEffect(() => {
    async function loadData() {
      var profile = await api.sendQuery(userQueries.me());

      if (!profile.data.data.me) {
        await api.logout();
        return;
      }

      RootStore.setProfile(profile.data.data.me);

      var campuses = await api.sendQuery(campusQueries.list());
      RootStore.setCampuses(campuses.data.data.campus);

      RootStore.setIsLoaded(true);
    }

    if (RootStore.isAuthenticated) {
      loadData();
    }
  }, [RootStore, RootStore.isAuthenticated]);

  const Routes = {
    dashboard: (
      <Route
        key="dashboard"
        path="/dashboard"
        exact
        component={DashboardPage}
      />
    ),
    orders: (
      <>
        <Route key="orders" path="/orders" exact>
          <OrdersOverviewPage type="admin"></OrdersOverviewPage>
        </Route>
      </>
    ),
    picker: (
      <Route key="picker" path="/picker" exact>
        <OrdersOverviewPage type="picker"></OrdersOverviewPage>
      </Route>
    ),
    delivery: (
      <Route key="delivery" path="/delivery" exact>
        <OrdersOverviewPage type="delivery"></OrdersOverviewPage>
      </Route>
    ),
    products: (
      <>
        <Route
          key="products"
          path="/products"
          exact
          component={ProductsOverview}
        />
        <Route
          key="product_detail"
          path="/products/:productId"
          exact
          component={ProductDetailPage}
        />
      </>
    ),
    users: (
      <Route key="users" path="/users" exact component={UsersOverviewPage} />
    ),
    campuses: (
      <>
        <Route
          key="campuses"
          path="/campuses"
          exact
          component={CampusOverviewPage}
        />
        <Route
          key="campus_detail"
          path="/campuses/:campusId"
          exact
          component={CampusDetailPage}
        />

        <Route
          key="store_detail"
          path="/campuses/:campusId/stores/:storeId"
          exact
          component={StoreDetailPage}
        />

        <Route
          key="location_detail"
          path="/campuses/:campusId/stores/:storeId/locations/:locationId"
          exact
          component={LocationDetailPage}
        />
      </>
    ),
  };

  const getDefaultRoute = () => {
    let redirect = "";
    if (RootStore.User?.isAdmin) redirect = "/dashboard";
    else if (RootStore.User?.isPicker) redirect = "/picker";
    else if (RootStore.User?.isDelivery) redirect = "/delivery";

    return (
      <Route key="default" path="/" exact>
        <Redirect to={redirect} />
      </Route>
    );
  };

  let routes = (
    <Switch>
      {RootStore.isAuthenticated && RootStore.isLoaded && RootStore.User ? (
        <>
          {!RootStore.User.isAdmin && !RootStore.User.campus ? (
            <>
              <Route
                path={RootStore.User?.isPicker ? "/picker" : "/delivery"}
                exact
              >
                <NoCampusSelected />
              </Route>

              <Redirect
                to={RootStore.User?.isPicker ? "/picker" : "/delivery"}
              ></Redirect>
            </>
          ) : (
            <>
              {Object.keys(Routes).map((key) =>
                RootStore.User?.hasAccessTo(key) ? Routes[key] : ""
              )}
              {getDefaultRoute()}
            </>
          )}

          <Route
            path="/orders/:orderId/print"
            component={OrderPrintPage}
            exact
          ></Route>

          <Route path="/login" exact>
            <Redirect to="/" />
          </Route>
        </>
      ) : (
        <>
          <Switch>
            <Route path="/login" exact component={LoginPage} />
            <Route path="/forgot" exact component={ForgotPassword} />
            <Route path="/reset" exact component={ResetPassword} />

            <Redirect to="/login" />
          </Switch>
        </>
      )}
    </Switch>
  );

  return (
    <Router>
      {RootStore.isAuthenticated !== undefined &&
      RootStore.isLoaded !== undefined ? (
        <Layout routes={Routes}>{routes}</Layout>
      ) : null}
    </Router>
  );
};

export default observer(App);
