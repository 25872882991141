import { types, flow, SnapshotIn, Instance } from "mobx-state-tree";
import { api } from "services";
import { productQueries } from "queries";
import Category from "./Category";
import Store from "./Store";
import alertUtil from "util/alertUtil";

const Product = types
  .model("Product", {
    id: types.identifier,
    name: types.string,
    description: types.string,
    image: types.maybeNull(types.string),
    price: types.number,
    cartken: types.boolean,
    weight: 0,
    demand: types.maybeNull(types.number),
    hasSpoon: types.boolean,
    stores: types.array(Store),
    categories: types.array(Category),
    created_at: types.maybe(types.string),
    updated_at: types.maybe(types.string),
    deleted_at: types.maybeNull(types.string),
    store_id: types.maybeNull(types.number),
  })
  .actions((self) => ({}))
  .actions((self) => ({
    update: flow(function* (
      product: any,
      categories: number[],
      file: any = undefined
    ) {
      let response;

      if (file) {
        response = yield api.sendQueryWithFile(
          productQueries.updateWithImage(product, categories),
          file
        );
      } else {
        response = yield api.sendQuery(
          productQueries.update(product, categories)
        );
      }

      if (response.data.data && response.data.data.updateProduct) {
        const data = response.data.data.updateProduct;
        self.name = data.name;
        self.description = data.description;
        self.image = data.image;
        self.price = data.price;
        self.cartken = data.cartken;
        self.hasSpoon = data.hasSpoon;
        self.categories = data.categories;

        alertUtil.success("Product updated");
      }
    }),
    updateStock: flow(function* (
      productId: string,
      storeId: string,
      inStock: boolean
    ) {
      const stores = self.stores.map((store) => ({
        id: store.id,
        inStock: store.id === storeId ? inStock : store.pivot?.inStock,
      }));

      const response = yield api.sendQuery(
        productQueries.updateStock(productId, stores)
      );

      if (response.data.data && response.data.data.updateProduct) {
        const data = response.data.data.updateProduct;
        self.stores = data.stores;
        alertUtil.success(`In stock of ${data.name} updated`);
      }
    }),
    updateWeight: flow(function* (weight: number) {
      console.log(self.weight);

      if (self.weight >= 0) {
        const response = yield api.sendQuery(
          productQueries.updateWeight(self.id, self.weight + weight)
        );

        if (response) {
          const data = response.data.data.updateWeight;
          self.weight = data.weight;
        }
      }
    }),
  }))
  .views((self) => ({
    get isFood() {
      return self.categories.some((category) => category.name === "Food");
    },

    get isSnack() {
      return self.categories.some((category) => category.name === "Snacks");
    },

    get isDrink() {
      return self.categories.some((category) => category.name === "Drinks");
    },
  }));

export type ProductSnapshot = SnapshotIn<typeof Product>;
export type ProductModel = Instance<typeof Product>;
export default Product;
