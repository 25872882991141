import { types, SnapshotIn, Instance } from "mobx-state-tree";

const LocationStatistics = types
  .model({
    name: types.string,
    orders: types.integer,
    products: types.integer,
    revenue: types.number,
  })
  .views((self) => ({
    get TableData() {
      return {
        name: self.name,
        orders: self.orders,
        products: self.products,
        revenue: Number(self.revenue).toFixed(2),
      };
    },
  }));

export type LocationStatisticsSnapshot = SnapshotIn<typeof LocationStatistics>;
export type LocationStatisticsModel = Instance<typeof LocationStatistics>;

export default LocationStatistics;
