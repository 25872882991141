import React, { useEffect, useState, useCallback } from "react";
import { RootStore } from "store";
import { Form, Button } from "react-bootstrap";
import { api } from "services";
import orderQueries from "queries/orderQueries";
import { observer } from "mobx-react-lite";
import {
  SplitOrdersOverview,
  OrdersOverview,
  CustomModal,
  OrderDetailModal,
  Loader,
} from "components";
import { PaginatorInfoModel } from "store/models/PaginatorInfo";
import DatePicker from "react-datepicker";
import moment from "moment";
import Select from "react-select";
import { CampusModel } from "store/models/Campus";
import { OrderModel } from "store/models/Order";
import OrderStatuses from "store/models/OrderStatuses";
import alertUtil from "util/alertUtil";

const OrdersOverviewPage = ({ type = "admin" }: { type: string }) => {
  const isPickerOverview = type === "picker";
  const isDeliveryOverview = type === "delivery";
  const isAdminOverview = type === "admin";

  const defaultActions = (cell, row) => (
    <div>
      <Button
        variant="light"
        onClick={() => {
          setSelectedOrder(row);
          setDetailModalVisible(true);
        }}
      >
        Show
      </Button>
    </div>
  );

  const getSplitOrdersPaginatorInfo = () => ({
    currentPage: 1,
    perPage: 1000,
  });

  const getDefaultOrdersPaginatorInfo = () => ({
    currentPage: 1,
    perPage: 10,
  });

  const paginatorInfo: PaginatorInfoModel | undefined =
    RootStore.OrderStore?.paginatorInfo;

  const defaultStatuses = isPickerOverview
    ? ["NEW"]
    : isDeliveryOverview
    ? ["IN_PROGRESS"]
    : Object.keys(OrderStatuses);

  const statusOptions = Object.keys(OrderStatuses).map((key) => {
    return {
      value: key,
      label: OrderStatuses[key],
    };
  });

  const defaultSelectedStatuses = defaultStatuses.map((key) => {
    return {
      value: key,
      label: OrderStatuses[key],
    };
  });

  const stores =
    isAdminOverview || RootStore.User?.isAdmin
      ? RootStore.CampusStore?.campuses
          .map((campus) => campus.stores)
          .reduce((result, store) => result.concat(store) as any)
      : (RootStore?.CampusStore?.campuses?.find(
          (x) => x.id === RootStore?.User?.campus?.id
        )?.stores as any);

  const storeOptions = stores
    ? stores
        .filter((store) => store.locations && store.locations.length > 0)
        .map((store) => {
          return {
            value: store.campus.id + "-" + store.id,
            label: store.name,
          };
        })
    : [];

  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<OrderModel>();

  const [locationOptions, setLocationOptions] = useState<any>([]);

  const [loading, setLoading] = useState(false);

  const [selectedFromDate, setSelectedFromDate] = useState(
    moment().startOf("day").toDate()
  );

  const [selectedToDate, setSelectedToDate] = useState(
    moment(selectedFromDate).add("1", "d").toDate()
  );

  const [selectedStatuses, setSelectedStatuses] = useState(defaultStatuses);
  const [selectedStore, setSelectedStore] = useState(
    storeOptions.length > 0 ? storeOptions[0].value : null
  );

  const [selectedLocation, setSelectedLocation] = useState<Number>();
  const [searchString, setSearchString] = useState("");

  const generateLocationOptions = useCallback(() => {
    if (!selectedStore) return [];
    const ids = selectedStore.split("-");
    const selectedCampusId = ids[0];
    const selectedStoreId = ids[1];

    const campus:
      | CampusModel
      | undefined = RootStore.CampusStore?.campuses.find(
      (campus) => campus.id === selectedCampusId
    );

    if (!campus) return [];

    const store = campus.stores.find((store) => store.id === selectedStoreId);

    if (store)
      setLocationOptions(
        store.locations.map((location) => {
          return { value: location.id, label: location.name };
        })
      );
  }, [selectedStore]);

  const loadData = useCallback(
    async (isRefresh: boolean = false) => {
      setLoading(true);

      let previousOrderCount = -1;

      if (RootStore.OrderStore)
        previousOrderCount = RootStore.OrderStore.orders.length;

      const response = await api.sendQuery(
        orderQueries.list(
          isAdminOverview ? paginatorInfo : getSplitOrdersPaginatorInfo(),
          selectedStatuses,
          selectedFromDate,
          selectedToDate,
          selectedLocation ? [selectedLocation] : [],
          searchString
        )
      );

      if (response.data.data && response.data.data.orders) {
        RootStore.setOrders(
          response.data.data.orders.data,
          isAdminOverview
            ? response.data.data.orders.paginatorInfo
            : getDefaultOrdersPaginatorInfo()
        );

        if (
          isRefresh &&
          !isAdminOverview &&
          previousOrderCount >= 0 &&
          response.data.data.orders.data.length > previousOrderCount
        ) {
          console.log("Im here!");
          alertUtil.ping();
        }

        setLoading(false);
      }
    },
    [
      isAdminOverview,
      paginatorInfo,
      selectedStatuses,
      selectedFromDate,
      selectedToDate,
      selectedLocation,
      searchString,
    ]
  );

  useEffect(() => {
    loadData();

    const interval = setInterval(async () => {
      loadData(true);
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [loadData]);

  useEffect(() => {
    generateLocationOptions();
  }, [generateLocationOptions]);

  return (
    <>
      {RootStore.OrderStore?.orders?.map(() => {
        return null;
      })}
      <div className="page-header">
        <h3 className="page-title position-relative">
          {isAdminOverview ? "Orders" : ""}
          {isPickerOverview ? "Picker" : ""}
          {isDeliveryOverview ? "Delivery" : ""}
          <Loader position="right" display={loading} />
        </h3>
      </div>

      {!isAdminOverview && !RootStore.User?.campus ? (
        <p>
          An administrator needs to assign you to a Campus before you can access
          this page
        </p>
      ) : (
        <>
          <div className="col-md-12">
            <div className="row">
              <div className="col-xl-3 col-lg-6">
                <Form.Group className="row">
                  <label className="col-sm-3 col-form-label">From</label>
                  <div className="col-sm-9">
                    <DatePicker
                      className="form-control w-100"
                      selected={selectedFromDate}
                      onChange={(date: Date) => {
                        setSelectedFromDate(date);
                      }}
                      dateFormat="dd-MM-yyyy"
                    />
                  </div>
                </Form.Group>
                <Form.Group className="row">
                  <label className="col-sm-3 col-form-label">To</label>
                  <div className="col-sm-9">
                    <DatePicker
                      className="form-control w-100"
                      selected={selectedToDate}
                      onChange={(date: Date) => {
                        setSelectedToDate(date);
                      }}
                      dateFormat="dd-MM-yyyy"
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-xl-4 col-lg-6">
                <Form.Group className="row">
                  <label className="col-sm-3 col-form-label">Store</label>
                  <div className="col-sm-9">
                    <Select
                      options={storeOptions}
                      onChange={(selectedStore) => {
                        setSelectedStore(selectedStore.value);
                      }}
                      defaultValue={storeOptions[0]}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="row">
                  <label className="col-sm-3 col-form-label">Location</label>
                  <div className="col-sm-9">
                    <Select
                      options={locationOptions}
                      onChange={(selectedLocation) => {
                        setSelectedLocation(
                          selectedLocation ? selectedLocation.value : ""
                        );
                      }}
                      isClearable="true"
                      defaultValue={locationOptions[0]}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-5">
                <Form.Group className="row">
                  <label className="col-sm-2 col-form-label">Status</label>
                  <div className="col-sm-10">
                    <Select
                      isMulti={true}
                      options={statusOptions}
                      onChange={(selectedStatuses) => {
                        if (selectedStatuses) {
                          setSelectedStatuses(
                            selectedStatuses.map((status) => status.value)
                          );
                        } else {
                          setSelectedStatuses([]);
                        }
                      }}
                      defaultValue={defaultSelectedStatuses}
                      isClearable="true"
                    />
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <Form.Group className="row">
                  <label className="col-sm-3 col-form-label">Search</label>
                  <div className="col-sm-9">
                    <Form.Control
                      type="number"
                      id="search"
                      placeholder="Order ID"
                      step="1"
                      min="0"
                      onChange={(event) => {
                        setSearchString(event.target.value);
                      }}
                    />
                  </div>
                </Form.Group>
              </div>
            </div>
          </div>

          {isPickerOverview || isDeliveryOverview ? (
            <SplitOrdersOverview
              type={type}
              loading={loading}
              defaultActions={defaultActions}
            />
          ) : (
            <OrdersOverview
              type={type}
              loading={loading}
              paginatorInfo={paginatorInfo}
              onPaginationChange={loadData}
              defaultActions={defaultActions}
            ></OrdersOverview>
          )}
        </>
      )}

      <CustomModal
        visible={detailModalVisible}
        onHide={() => setDetailModalVisible(false)}
        modalTitle="Order details"
      >
        {selectedOrder ? (
          <OrderDetailModal
            order={selectedOrder}
            printMode={false}
          ></OrderDetailModal>
        ) : (
          ""
        )}
      </CustomModal>
    </>
  );
};

export default observer(OrdersOverviewPage);
