import { types, SnapshotIn, Instance } from "mobx-state-tree";
import LocationStatistics from "./LocationStatistics";

const Statistics = types
  .model({
    revenue: types.number,
    avg_spending: types.number,
    demand: types.number,
    notPicked: types.integer,
    picked: types.integer,
    orders: types.integer,
    rating: types.maybeNull(types.number),
    locations: types.array(LocationStatistics),
  })
  .views((self) => ({
    get TableData() {
      return {
        revenue: Number(self.revenue).toFixed(2),
        avg_spending: Number(self.avg_spending).toFixed(2),
        demand: self.demand,
        notPicked: self.notPicked + "/" + self.orders,
        picked: self.picked + "/" + self.orders,
        rating: Number(self.rating).toFixed(2),
        locations: self.locations.map((x) => x.TableData),
      };
    },
  }));

export type StatisticsSnapshot = SnapshotIn<typeof Statistics>;
export type StatisticsModel = Instance<typeof Statistics>;

export default Statistics;
