import React, { useState } from "react";
import { ButtonGroup, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import ConfirmModal from "./ConfirmModal";
import { StoreModel } from "store/models/Store";
import CustomModal from "./CustomModal";
import { LocationForm } from "./forms";
import { observer } from "mobx-react-lite";
import { LocationSnapshot } from "store/models/Location";
import { RootStore } from "store";

const LocationsOverview = ({
  campusId,
  store,
}: {
  campusId: string;
  store: StoreModel;
}) => {
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({
    id: "",
    name: "",
  });

  const onSubmit = async (locationData: LocationSnapshot, imageFile) => {
    locationData.store_id = store.id;
    await store.createLocation(locationData, imageFile);
    setCreateModalVisible(false);
  };

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "action",
      text: "Actions",
      sort: false,
      formatter: function Formatter(cell, row) {
        return (
          <div>
            <ButtonGroup>
              <Link
                className="btn btn-outline-primary"
                to={
                  "/campuses/" +
                  campusId +
                  "/stores/" +
                  store.id +
                  "/locations/" +
                  row.id
                }
              >
                <i className="mdi mdi-eye-outline text-primary"></i>View
              </Link>

              {RootStore.User?.isAdmin ? (
                <Button
                  onClick={() => {
                    setSelectedLocation(row);
                    setDeleteModalVisible(true);
                  }}
                  variant="outline-danger"
                >
                  <i className="mdi mdi-delete"></i>Remove
                </Button>
              ) : (
                ""
              )}
            </ButtonGroup>
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  return (
    <>
      {store?.locations?.map((x) => {
        return null;
      })}
      <div>
        <div className="page-header">
          <h3 className="page-title">Locations</h3>
          {RootStore.User?.isAdmin ? (
            <>
              <Button onClick={() => setCreateModalVisible(true)}>
                + Add Location
              </Button>
              <CustomModal
                visible={createModalVisible}
                onHide={() => setCreateModalVisible(false)}
                modalTitle="Add Location"
                size="xl"
              >
                <LocationForm onSubmitCallback={onSubmit} />
              </CustomModal>
            </>
          ) : (
            ""
          )}
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Overview</h4>
                <div className="row">
                  <div className="col-12">
                    <ToolkitProvider
                      keyField="id"
                      bootstrap4
                      data={store.locations}
                      columns={columns}
                    >
                      {(props) => (
                        <div>
                          <BootstrapTable
                            defaultSorted={defaultSorted}
                            {...props.baseProps}
                            wrapperClasses="table-responsive"
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmModal
        show={deleteModalVisible}
        onHide={() => setDeleteModalVisible(false)}
        onConfirm={() => {
          store.removeLocation(selectedLocation.id);
          setDeleteModalVisible(false);
        }}
        modalText="Are you sure you want to remove this location?"
        modalTitle={"Remove " + selectedLocation.name}
      ></ConfirmModal>
    </>
  );
};

export default observer(LocationsOverview);
