import { types, flow, Instance, SnapshotIn } from "mobx-state-tree";
import { api } from "services";
import { locationQueries, timeSlotQueries } from "queries";
import TimeSlot, { TimeSlotSnapshot, TimeSlotModel } from "./TimeSlot";
import alertUtil from "util/alertUtil";

const Location = types
  .model("Location", {
    id: types.identifier,
    name: types.string,
    address: types.string,
    zipcode: types.string,
    city: types.string,
    lat: types.string,
    lng: types.string,
    description: types.maybeNull(types.string),
    image: types.maybeNull(types.string),
    color: types.maybeNull(types.string),
    max_orders: types.maybeNull(types.number),
    max_products: types.maybeNull(types.number),
    timeslot_safeguard: types.maybeNull(types.number),
    type: types.string,
    timeslots: types.array(TimeSlot),
    store_id: types.maybeNull(types.string),
  })
  .actions((self) => ({
    addTimeSlot(timeSlot: TimeSlotSnapshot) {
      if (self.timeslots) self.timeslots = [...self.timeslots, timeSlot] as any;
      else self.timeslots = [timeSlot] as any;
    },

    deleteTimeSlot(id: string) {
      if (self.timeslots) {
        const timeSlot = self.timeslots.find(
          (timeSlot) => timeSlot.id === id
        ) as TimeSlotModel;
        self.timeslots.remove(timeSlot);
      }
    },
  }))
  .actions((self) => ({
    update: flow(function* (id, location, file: any = undefined) {
      let query, response;

      if (file) {
        query = locationQueries.updateWithImage(id, location);
        response = yield api.sendQueryWithFile(query, file);
      } else {
        query = locationQueries.update(id, location);
        response = yield api.sendQuery(query);
      }

      if (response.data.data && response.data.data.updateLocation) {
        const data = response.data.data.updateLocation;
        self.name = data.name;
        self.image = data.image;
        self.address = data.address;
        self.zipcode = data.zipcode;
        self.city = data.city;
        self.lat = data.lat;
        self.lng = data.lng;
        self.description = data.description;
        self.type = data.type;
        self.color = data.color;
        self.max_orders = data.max_orders;
        self.max_products = data.max_products;
        self.timeslot_safeguard = data.timeslot_safeguard;

        alertUtil.success("Location updated");
      }
    }),

    createTimeSlot: flow(function* (timeSlot) {
      const response = yield api.sendQuery(timeSlotQueries.create(timeSlot));

      if (response.data.data && response.data.data.createTimeSlot) {
        self.addTimeSlot(response.data.data.createTimeSlot);
        alertUtil.success("Time slot created");
      }
    }),

    removeTimeSlot: flow(function* (id) {
      const response = yield api.sendQuery(timeSlotQueries.remove(id));
      if (response.data.data && response.data.data.deleteTimeSlot) {
        self.deleteTimeSlot(id);
        alertUtil.success("Timeslot deleted");
      }
    }),
  }));

export type LocationSnapshot = SnapshotIn<typeof Location>;
export type LocationModel = Instance<typeof Location>;
export default Location;
