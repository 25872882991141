import React, { useState } from "react";
import { RootStore } from "store";
import { Link } from "react-router-dom";
import { ConfirmModal, LocationForm, TimeSlotsOverview } from "components";
import { observer } from "mobx-react-lite";
import { StoreModel } from "store/models/Store";
import { LocationModel } from "store/models/Location";
import { CampusModel } from "store/models/Campus";

const LocationDetailPage = ({
  history,
  match,
}: {
  history: any;
  match: { params };
}) => {
  const campus: CampusModel = RootStore.CampusStore?.campuses.find(
    (campus) => campus.id === match.params.campusId
  ) as CampusModel;

  const store: StoreModel = campus?.stores?.find(
    (store) => store.id === match.params.storeId
  ) as StoreModel;

  const location: LocationModel = store?.locations?.find(
    (location) => location.id === match.params.locationId
  ) as LocationModel;

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const onSubmit = async (locationData, imageFile) => {
    await location?.update(location?.id, locationData, imageFile);
  };

  return (
    <>
      <div>
        <div className="page-header">
          <h3 className="page-title">{location?.name}</h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/campuses">Campuses</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                <Link to={"/campuses/" + campus?.id}>{campus?.name}</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                Stores
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <Link to={"/campuses/" + campus.id + "/stores/" + store.id}>
                  {store?.name}
                </Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                Locations
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {location?.name}
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <LocationForm
              onSubmitCallback={onSubmit}
              location={location}
              onRemoveClick={() => {
                setDeleteModalVisible(true);
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <TimeSlotsOverview location={location} />
          </div>
        </div>
      </div>

      <ConfirmModal
        show={deleteModalVisible}
        onHide={() => setDeleteModalVisible(false)}
        onConfirm={async () => {
          if (location && location.id) {
            const result = await store?.removeLocation(location.id);

            setDeleteModalVisible(false);

            if (result)
              return history.push(`/campuses/${campus?.id}/stores/${store.id}`);
          }
        }}
        modalText="Are you sure you want to remove this location?"
        modalTitle={"Remove " + location?.name}
      ></ConfirmModal>
    </>
  );
};

export default observer(LocationDetailPage);
