import axios, { AxiosResponse, AxiosInstance } from "axios";
import moment from "moment";
import { authQueries } from "queries";
import { RootStore } from "store";
import errorUtil from "util/errorUtil";

class apiService {
  instance: AxiosInstance;
  currentToken: TokenInterface | null;

  constructor() {
    this.instance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });

    this.currentToken = this.loadAccessToken();

    if (this.currentToken) {
      if (this.currentToken.tokenExpireDate <= moment()) {
        this.refreshToken();
      } else {
        RootStore.setIsAuthenticated(true);
      }
    } else {
      RootStore.setIsAuthenticated(false);
      RootStore.setIsLoaded(false);
    }

    this.setupInterceptors();
  }

  public sendQuery = async (
    query,
    forceRefresh: boolean = false
  ): Promise<AxiosResponse> => {
    const now = moment();

    if (
      forceRefresh ||
      (this.currentToken && this.currentToken.tokenExpireDate <= now)
    ) {
      await this.refreshToken();
    }

    return this.instance.post("/graphql", query);
  };

  public sendQueryWithFile = async (
    query,
    file,
    forceRefresh: boolean = false
  ): Promise<AxiosResponse> => {
    const request = new FormData();
    const map = JSON.stringify({ file: ["variables.file"] });

    request.append("file", file);
    request.append("operations", JSON.stringify(query));
    request.append("map", map);
    return await this.sendQuery(request, forceRefresh);
  };

  private loadAccessToken = () => {
    const accessToken = localStorage.getItem("access_token");

    if (!accessToken) {
      return null;
    }

    const tokenExpireDate = moment(localStorage.getItem("token_expire_date"));
    this.setAccessTokenHeader(accessToken);

    return { accessToken, tokenExpireDate };
  };

  public setAccessToken = (token: string, token_expires_in: number) => {
    const tokenExpireDate = moment();
    tokenExpireDate.add(token_expires_in, "s");
    localStorage.setItem("token_expire_date", tokenExpireDate.toISOString());
    localStorage.setItem("access_token", token);

    this.currentToken = { accessToken: token, tokenExpireDate };
    this.setAccessTokenHeader(token);
    RootStore.setIsAuthenticated(true);
  };

  public setAccessTokenHeader = (token: string) => {
    this.instance.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
  };

  public login = async (
    username: string,
    password: string
  ): Promise<boolean> => {
    var response = await this.sendQuery(
      authQueries.login(username, password),
      false
    );

    if (response.data.data.loginAdmin) {
      var authResponse = response.data.data.loginAdmin;

      this.setAccessToken(authResponse.access_token, authResponse.expires_in);
      return true;
    }

    return false;
  };

  public logout = async () => {
    const response = await this.sendQuery(authQueries.logout(), false);

    if (response.data.data.logout) {
      RootStore.reset();
      this.clearLocalStorage();
    }
  };

  private refreshToken = async (): Promise<boolean> => {
    var response = await this.instance.post("/graphql", authQueries.refresh());
    var authResponse = response.data.data.refresh;

    if (authResponse) {
      this.setAccessToken(authResponse.access_token, authResponse.expires_in);
      return true;
    }

    this.clearLocalStorage();
    return false;
  };

  private clearLocalStorage() {
    localStorage.removeItem("token_expire_date");
    localStorage.removeItem("access_token");
  }

  private setupInterceptors = () => {
    this.instance.interceptors.response.use((response) => {
      if (response.data.errors && response.data.errors.length > 0) {
        errorUtil.handleError(response.data.errors[0].message);
      }

      return response;
    });
  };
}

interface TokenInterface {
  accessToken: string;
  tokenExpireDate: moment.Moment;
}

export default new apiService();
