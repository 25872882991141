import React, { useState } from "react";
import { RootStore } from "store";
import { Link } from "react-router-dom";
import { StoreForm, ConfirmModal } from "components";
import { observer } from "mobx-react-lite";
import { LocationsOverview, ProductsOverview } from "components";
import { StoreModel, StoreSnapshot } from "store/models/Store";
import { CampusModel } from "store/models/Campus";

const StoreDetailPage = ({ history, match }) => {
  const campus: CampusModel = RootStore.CampusStore?.campuses.find(
    (campus) => campus.id === match.params.campusId
  ) as CampusModel;

  const store: StoreModel = campus?.stores?.find(
    (store) => store.id === match.params.storeId
  ) as StoreModel;
  
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const onSubmit = async (storeData: StoreSnapshot, imageFile) => {
    storeData.campus_id = campus.id;
    await store.update(storeData, imageFile);
  };

  return (
    <>
      <div>
        <div className="page-header">
          <h3 className="page-title">{store?.name}</h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/campuses">Campuses</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                <Link to={"/campuses/" + campus?.id}>{campus?.name}</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                Stores
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {store?.name}
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-6 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Store details</h4>
                <StoreForm
                  onSubmitCallback={onSubmit}
                  store={store}
                  onRemoveClick={() => {
                    setDeleteModalVisible(true);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-lg-12">
            {store?.locations ? (
              <LocationsOverview campusId={campus.id} store={store} />
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <ProductsOverview store={store} />
          </div>
        </div>
      </div>

      <ConfirmModal
        show={deleteModalVisible}
        onHide={() => setDeleteModalVisible(false)}
        onConfirm={async () => {
          if (store && store.id) {
            const result = await campus?.removeStore(store.id);
            setDeleteModalVisible(false);

            if (result) return history.push("/campuses/" + campus?.id);
          }
        }}
        modalText="Are you sure you want to archive this store?"
        modalTitle={"Archive " + store?.name}
      ></ConfirmModal>
    </>
  );
};

export default observer(StoreDetailPage);
