import React from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { RootStore } from "store";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router";

const Layout = ({ routes, children }) => {
  let location = useLocation();

  return !location.pathname.includes("print") ? (
    <div className="container-scroller">
      {RootStore.isAuthenticated && RootStore.isLoaded ? <Navbar /> : <></>}
      <div className="container-fluid page-body-wrapper">
        {RootStore.isAuthenticated && RootStore.isLoaded ? (
          <Sidebar routes={routes} />
        ) : (
          <></>
        )}
        <div className="main-panel">
          <div className="content-wrapper">{children}</div>
        </div>
      </div>
    </div>
  ) : (
    <>{children}</>
  );
};

export default observer(Layout);
