import React from "react";
import { Trans } from "react-i18next";
import { api } from "services";

const Navbar = (props) => {
  const toggleOffcanvas = () => {
    document.querySelector(".sidebar-offcanvas")?.classList.toggle("active");
  };

  const handleLogout = async () => {
    api.logout();
  };

  return (
    <nav className="navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row">
      <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
        <a
          className="navbar-brand brand-logo-mini align-self-center d-lg-none"
          href="/dashboard"
        >
          <img src={require("../assets/images/logo-small.png")} alt="logo" />
        </a>

        <button
          className="navbar-toggler navbar-toggler align-self-center"
          type="button"
          onClick={() => document.body.classList.toggle("sidebar-icon-only")}
        >
          <i className="mdi mdi-menu"></i>
        </button>
        <ul className="navbar-nav navbar-nav-right header-links">
          <li className="nav-item d-lg-flex">
            <a
              href="!#"
              onClick={(evt) => {
                evt.preventDefault();
                handleLogout();
              }}
              className="nav-link"
            >
              <i className="mdi mdi-logout"></i>
              <Trans>Log out</Trans>
            </a>
          </li>
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          onClick={toggleOffcanvas}
        >
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
