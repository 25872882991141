import timeSlotQueries from "./timeSlotQueries";
import locationQueries from "./locationQueries";
import productQueries from "./productQueries";

import moment from "moment";
import OrderStatuses from "store/models/OrderStatuses";

const fields = `
  id  
  status
  pickUpPinCode 
  created_at
  updated_at
  paid_at
  log {
    msg
    created_at
  }
`;

const fieldsWithChildren = `
  ${fields}
  items {
    id
    product {
      ${productQueries.fields}
    }
    quantity
  }
  location {
    ${locationQueries.fields}
  }
  timeslot {
    ${timeSlotQueries.fields}
  }
  user {
    id
    email
  }
`;

const single = (id) => ({
  query: `
  {
    order(id: ${id}) {
      ${fieldsWithChildren}
    }
  }
  `,
});

const list = (
  paginatorInfo = { currentPage: 1, perPage: 10 },
  statuses = Object.keys(OrderStatuses),
  from = moment().subtract(10, "d").toDate(),
  to = moment().add(1, "d").toDate(),
  locations = [],
  search = ""
) => {
  from = moment(from).format("yyyy-MM-DD");
  to = moment(to).format("yyyy-MM-DD");

  let query = `
  {
    orders(
      first: ${paginatorInfo.perPage}
      page: ${paginatorInfo.currentPage}
      status: [ ${statuses.join(",")}]
      paid_at: {
        from: "${from}"
        to: "${to}"
      }
      
      ${search.length > 0 ? `search: "${search}"` : ``}

      ${locations.length > 0 ? `locations: ${JSON.stringify(locations)}` : ``}

      ) {
      data {
        ${fieldsWithChildren}
      }
      paginatorInfo {
        currentPage
        lastPage
        perPage
      }
    }
  }
  `;

  return {
    query,
  };
};

const update = (order) => ({
  query: `
    mutation {
      updateOrder(
          id: ${order.id},
          input: {
        status: ${order.status}
      }) {
        id
        status
      }
    }
  `,
});

export default {
  list,
  update,
  fields,
  single,
};
