const login = (email, password) => ({
  query: `
    mutation {
        loginAdmin (
            email: "${email}",
            password: "${password}"
        ){
            access_token,
            expires_in
        }
    }
  `,
});

const refresh = () => ({
  query: `
    mutation {
      refresh {
          access_token,
          expires_in
      }
    }
  `,
});

const logout = () => ({
  query: `
    mutation {
      logout
    }
  `,
});

const forgot = (email) => ({
  query: `
    mutation {
      forgot (
        email: "${email}"
      )
    }
  `,
});

const reset = (password, token) => ({
  query: `
    mutation {
      reset (
        password: "${password}"
        token: "${token}"
      )
    }
  `,
});

export default {
  login,
  refresh,
  logout,
  forgot,
  reset,
};
