import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { PaginatorInfoModel } from "store/models/PaginatorInfo";
import { observer } from "mobx-react-lite";
import Select from "react-select";

const Pagination = ({
  paginatorInfo,
  onPaginationChange,
}: {
  paginatorInfo: PaginatorInfoModel;
  onPaginationChange: () => void;
}) => {
  const perPageOptions = [10, 25, 50];

  const onPerPageChanged = (value) => {
    paginatorInfo.setPerPage(Number(value));
    paginatorInfo.setCurrentPage(1);

    onPaginationChange();
  };

  return (
    <div className="row mt-3">
      <div className="col-md-4 col-xs-4 col-sm-4 col-lg-4 d-inline-flex justify-content-start">
        <Select
          onChange={(selectedPerPage) => {
            onPerPageChanged(selectedPerPage.value);
          }}
          options={perPageOptions.map((option) => {
            return {
              value: option,
              label: option,
            };
          })}
          className="w-100"
          defaultValue={{ value: 10, label: 10 }}
          menuPlacement="top"
          isSearchable={false}
        />
      </div>
      {paginatorInfo.lastPage > 1 ? (
        <div className="col-md-8 col-xs-8 col-sm-8 col-lg-8 w-100 d-inline-flex justify-content-end">
          <ButtonGroup className="pagination w-100">
            {paginatorInfo.currentPage === paginatorInfo.lastPage &&
            paginatorInfo.currentPage >= 3 ? (
              <Button
                variant="light"
                onClick={() => {
                  paginatorInfo.setCurrentPage(paginatorInfo.currentPage - 2);
                  onPaginationChange();
                }}
              >
                {paginatorInfo.currentPage - 2}
              </Button>
            ) : (
              <></>
            )}

            {paginatorInfo.currentPage > 1 ? (
              <Button
                variant="light"
                onClick={() => {
                  paginatorInfo.setCurrentPage(paginatorInfo.currentPage - 1);
                  onPaginationChange();
                }}
              >
                {paginatorInfo.currentPage - 1}
              </Button>
            ) : (
              <></>
            )}

            <Button>{paginatorInfo.currentPage}</Button>

            {paginatorInfo.lastPage > paginatorInfo.currentPage ? (
              <Button
                variant="light"
                onClick={() => {
                  paginatorInfo.setCurrentPage(paginatorInfo.currentPage + 1);
                  onPaginationChange();
                }}
              >
                {paginatorInfo.currentPage + 1}
              </Button>
            ) : (
              <></>
            )}

            {paginatorInfo.currentPage === 1 && paginatorInfo.lastPage >= 3 ? (
              <Button
                variant="light"
                onClick={() => {
                  paginatorInfo.setCurrentPage(paginatorInfo.currentPage + 2);
                  onPaginationChange();
                }}
              >
                {paginatorInfo.currentPage + 2}
              </Button>
            ) : (
              <></>
            )}
          </ButtonGroup>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default observer(Pagination);
