import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import storageUtil from "util/storageUtil";
import DatePicker from "react-datepicker";
import { StoreModel, StoreSnapshot } from "store/models/Store";
import moment from "moment";
import { RootStore } from "store";
import Loader from "components/Loader";

const StoreForm = ({
  onSubmitCallback,
  store = undefined,
  onRemoveClick = undefined,
}: {
  onSubmitCallback: (store: StoreSnapshot, imageFile) => Promise<void>;
  store?: StoreModel;
  onRemoveClick?: () => void;
}) => {
  const getDefaultTime = (property): Date => {

    if (store && store[property]) {
      return moment(store[property], "HH:mm").toDate();
    }

    const date = new Date();
    date.setMinutes(date.getMinutes() > 30 ? 30 : 0);

    return date;
  };

  const [loading, setLoading] = useState(false);
  const [storeFile, setStoreFile] = useState<any>();
  const [selectedTime, setSelectedTime] = useState(getDefaultTime('last_pickup_time'));
  const [selectedClosed, setSelectedClosed] = useState(getDefaultTime('cartkenClosedFrom'));
  const [selectedOpen, setSelectedOpen] = useState(getDefaultTime('cartkenOpenFrom'));

  const onFileChange = (event) => {
    setStoreFile(event.target.files[0]);
  };

  const onSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    const storeData: StoreSnapshot = {
      id: store && store.id ? store?.id : "",
      name: event.target[1].value,
      address: event.target[2].value,
      last_pickup_time: event.target[3].value,
      cartkenOpenFrom: event.target[4].value,
      cartkenClosedFrom: event.target[5].value
    };

    const image = event.target[6].files[0];

    await onSubmitCallback(storeData, image);
    setLoading(false);
  };

  return (
    <Form
      onSubmit={async (event) => {
        await onSubmit(event);
      }}
    >
      <fieldset disabled={!RootStore.User?.isAdmin}>
        <Form.Group>
          <label htmlFor="name">Name *</label>
          <Form.Control
            type="text"
            id="name"
            placeholder="Name"
            defaultValue={store?.name}
            required
          />
        </Form.Group>
        <Form.Group>
          <label htmlFor="address">Address *</label>
          <Form.Control
            type="text"
            id="address"
            placeholder="Address"
            defaultValue={store?.address?.toString()}
            required
          />
        </Form.Group>
        <Form.Group>
          <label htmlFor="name">Last pickup time *</label>
          <div className="col-md-12">
            <DatePicker
              className="form-control"
              selected={selectedTime}
              onChange={(date: Date) => setSelectedTime(date)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="HH:mm"
              timeFormat="HH:mm"
              required
            />
          </div>
        </Form.Group>
        <Form.Group>
          <label htmlFor="name">Cartken open from *</label>
          <div className="col-md-12">
            <DatePicker
                className="form-control"
                selected={selectedOpen}
                onChange={(date: Date) => setSelectedOpen(date)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                required
            />
          </div>
        </Form.Group>
        <Form.Group>
          <label htmlFor="name">Cartken closed from *</label>
          <div className="col-md-12">
            <DatePicker
                className="form-control"
                selected={selectedClosed}
                onChange={(date: Date) => setSelectedClosed(date)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                required
            />
          </div>
        </Form.Group>
        <Form.Group>
          <label>Image *</label>
          <div className="custom-file">
            <Form.Control
              type="file"
              className="form-control visibility-hidden"
              id="storeImage"
              onChange={onFileChange}
              required={!!!store}
            />
            <label className="custom-file-label" htmlFor="storeImage">
              {storeFile
                ? storeFile.name
                : store?.image
                ? storageUtil.getFileName(store.image)
                : "Choose image"}
            </label>
          </div>
          {storeFile ? (
            <img
              className="img-lg mt-2"
              alt="Store"
              src={URL.createObjectURL(storeFile)}
            ></img>
          ) : store?.image ? (
            <img
              className="img-lg mt-2"
              alt="Store"
              src={storageUtil.getStoragePath(store.image)}
            ></img>
          ) : (
            ""
          )}
        </Form.Group>

        <Button
          type="submit"
          className="btn btn-primary mr-2 position-relative"
        >
          <Loader
            position={store ? "left" : "right"}
            display={loading}
          ></Loader>
          {store ? "Save" : "Add"}
        </Button>

        {store ? (
          <Button variant="warning" onClick={onRemoveClick}>
            Archive
          </Button>
        ) : (
          ""
        )}
      </fieldset>
    </Form>
  );
};

export default StoreForm;
