import React from "react";
import { RootStore } from "store";
import { ButtonGroup } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { observer } from "mobx-react-lite";
import { Pagination } from "components";
import { PaginatorInfoModel } from "store/models/PaginatorInfo";
import moment from "moment";

const OrdersOverview = ({
  type,
  loading,
  paginatorInfo,
  onPaginationChange,
  defaultActions,
}: {
  type: string;
  loading: boolean;
  paginatorInfo: PaginatorInfoModel | undefined;
  onPaginationChange: () => void;
  defaultActions: any;
}) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      sort: true,
    },
    {
      dataField: "created_at",
      text: "Date",
      sort: true,
      formatter: function Formatter(cell, row) {
        return moment(cell).format("DD-MM-yyyy");
      },
    },
    {
      dataField: "timeslot.from",
      text: "Timeslot",
      sort: true,
      formatter: function Formatter(cell, row) {
        return row.timeslot.from + " - " + row.timeslot.to;
      },
    },
    {
      dataField: "email",
      text: "Name",
      sort: true,
    },
    {
      dataField: "totalItems",
      text: "# items",
      sort: true,
    },
    {
      dataField: "totalPriceWithDelivery",
      text: "# total",
      sort: true,
      formatter: function Formatter(cell, row) {
        return "€" + Number(cell).toFixed(2);
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
    {
      dataField: "action",
      text: "Actions",
      sort: false,
      formatter: function Formatter(cell, row) {
        return <ButtonGroup>{defaultActions(cell, row)}</ButtonGroup>;
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title position-relative">Overview</h4>

              {RootStore.OrderStore &&
              RootStore.OrderStore.orders.length > 0 ? (
                <div className="row">
                  <div className="col-12">
                    <ToolkitProvider
                      keyField="id"
                      bootstrap4
                      data={RootStore.OrderStore?.TableData}
                      columns={columns}
                    >
                      {(props) => (
                        <div>
                          <BootstrapTable
                            defaultSorted={defaultSorted}
                            {...props.baseProps}
                            wrapperClasses="table-responsive"
                          />
                        </div>
                      )}
                    </ToolkitProvider>

                    {paginatorInfo ? (
                      <Pagination
                        paginatorInfo={paginatorInfo}
                        onPaginationChange={onPaginationChange}
                      ></Pagination>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                "No results found"
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(OrdersOverview);
