import React, { useState } from "react";
import { api } from "services";
import { Form } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { Loader } from "components";

const LoginPage = () => {
  const [loading, setLoading] = useState(false);

  const handleLogin = async (event) => {
    setLoading(true);
    event.preventDefault();
    const result = await api.login(
      event.target[0].value,
      event.target[1].value
    );

    if (!result) setLoading(false);
  };

  return (
    <div>
      <div className="d-flex align-items-center auth px-0">
        <div className="row w-100 mx-0">
          <div className="col-xl-4 col-md-8 mx-auto">
            <div className="auth-form-light text-left py-5 px-4 px-sm-5">
              <div className="brand-logo">
                <img src={require("../../assets/images/logo.png")} alt="logo" />
              </div>
              <h4>SPAR Delivery</h4>
              <h6 className="font-weight-light">Log in to continue.</h6>
              <Form className="pt-3" onSubmit={handleLogin}>
                <Form.Group className="d-flex search-field">
                  <Form.Control
                    type="email"
                    placeholder="E-mail address"
                    size="lg"
                    className="h-auto"
                    required
                  />
                </Form.Group>
                <Form.Group className="d-flex search-field">
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    size="lg"
                    className="h-auto"
                    required
                  />
                </Form.Group>
                <div className="mt-3">
                  <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn position-relative">
                    LOG IN
                    <Loader
                      display={loading}
                      position={"right"}
                      style={{ top: "6px", right: "15px" }}
                      size={40}
                    ></Loader>
                  </button>
                </div>
                <div className="my-2 d-flex justify-content-between align-items-center">
                  <Link to="forgot" className="auth-link text-black">
                    Forgot password?
                  </Link>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(LoginPage);
