import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { UserModel, UserSnapshot } from "store/models/User";
import { RootStore } from "store";
import alertUtil from "util/alertUtil";
import Loader from "components/Loader";

const UserForm = ({
  onSubmitCallback,
  user = undefined,
}: {
  onSubmitCallback: (userData: UserSnapshot, roles: number[]) => Promise<void>;
  user?: UserModel;
}) => {
  const onSubmit = async (event) => {
    setLoading(true);

    event.preventDefault();

    const userData: UserSnapshot = {
      id: user ? user.id : "",
      email: event.target[0].value,
      password: event.target[1].value,
      campus_id: event.target[5].value,
    };

    if (selectedRoles.length > 0)
      await onSubmitCallback(userData, selectedRoles);
    else alertUtil.error("You can't add/update a user without any roles");

    setLoading(false);
  };

  const [selectedRoles, setSelectedRoles] = useState(
    user ? user.roles.map((role) => Number(role.id)) : []
  );

  const [loading, setLoading] = useState(false);

  const onRoleChecked = (checked, role: number) => {
    const roles = [...selectedRoles];
    if (checked && !roles.includes(role)) {
      roles.push(role);
    }

    if (!checked && roles.includes(role)) {
      const index = roles.indexOf(role);
      roles.splice(index, 1);
    }

    setSelectedRoles(roles);
  };

  return (
    <Form onSubmit={onSubmit}>
      <Form.Group>
        <label htmlFor="email">E-mail address</label>
        <Form.Control
          type="email"
          id="email"
          placeholder="E-mail address"
          defaultValue={user?.email}
          required
          disabled={user ? true : false}
        />
      </Form.Group>

      <Form.Group>
        <label htmlFor="password">Password</label>
        <Form.Control
          type="password"
          id="password"
          required
          defaultValue={user ? "********" : ""}
          disabled={user ? true : false}
        />
      </Form.Group>

      <Form.Group>
        <label htmlFor="role">Roles</label>
        <Form.Check
          type="checkbox"
          id={`role-admin`}
          label={`Admin`}
          defaultChecked={user?.isAdmin}
          onClick={(e) => onRoleChecked(e.target.checked, 1)}
        />
        <Form.Check
          type="checkbox"
          id={`role-picker`}
          label={`Picker`}
          defaultChecked={user?.isPicker}
          onClick={(e) => onRoleChecked(e.target.checked, 2)}
        />
        <Form.Check
          type="checkbox"
          id={`role-delivery`}
          label={`Delivery`}
          defaultChecked={user?.isDelivery}
          onClick={(e) => onRoleChecked(e.target.checked, 3)}
        />
      </Form.Group>

      <Form.Group>
        <label htmlFor="campus">Campus</label>

        <Form.Control
          as="select"
          custom
          defaultValue={user?.campus ? user.campus.id : ""}
        >
          <option value="">--- No campus ---</option>
          {RootStore.CampusStore?.campuses.map((campus, i) => {
            return <option value={campus.id}>{campus.name}</option>;
          })}
        </Form.Control>
      </Form.Group>

      <Button
        type="submit"
        disabled={selectedRoles.length <= 0}
        className="btn btn-primary mr-2 position-relative"
      >
        <Loader position="right" display={loading}></Loader>
        {user ? "Save" : "Add"}
      </Button>
    </Form>
  );
};

export default UserForm;
