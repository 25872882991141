import React from "react";
import { api } from "services";
import { Form } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { authQueries } from "queries";
import alertUtil from "util/alertUtil";
import { useHistory } from "react-router";

const ForgotPassword = () => {
  const history = useHistory();

  const handleSubmit = async (event) => {
    if (event.currentTarget.checkValidity()) {
      event.preventDefault();
      const response = await api.sendQuery(
        authQueries.forgot(event.target[0].value)
      );

      if (!response.data.errors) {
        alertUtil.success(
          "An email has been sent to the specified e-mail address"
        );

        return history.push("/login");
      }
    }
  };

  return (
    <div>
      <div className="d-flex align-items-center auth px-0">
        <div className="row w-100 mx-0">
          <div className="col-xl-4 col-md-8 mx-auto">
            <div className="auth-form-light text-left py-5 px-4 px-sm-5">
              <div className="brand-logo">
                <img src={require("../../assets/images/logo.png")} alt="logo" />
              </div>
              <h4>SPAR Delivery</h4>
              <h6 className="font-weight-light">Forgot password</h6>
              <Form className="pt-3" onSubmit={handleSubmit}>
                <Form.Group className="d-flex search-field">
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    size="lg"
                    className="h-auto"
                    required
                  />
                </Form.Group>
                <div className="mt-3">
                  <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">
                    SUBMIT
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(ForgotPassword);
