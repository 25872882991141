import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { CampusClosedSnapshot } from "store/models/CampusClosed";
import Loader from "components/Loader";

const CampusClosedForm = ({
  onSubmitCallback,
}: {
  onSubmitCallback: (campusClosedData: CampusClosedSnapshot) => Promise<void>;
}) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const onSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    const campusClosedData: CampusClosedSnapshot = {
      id: "",
      date: event.target[0].value,
    };

    await onSubmitCallback(campusClosedData);
    setLoading(false);
  };

  return (
    <Form onSubmit={onSubmit}>
      <Form.Group>
        <label htmlFor="name">Date</label>
        <div className="col-md-12">
          <DatePicker
            className="form-control"
            selected={selectedDate}
            onChange={(date: Date) => setSelectedDate(date)}
            dateFormat="dd-MM-yyyy"
            required
          />
        </div>
      </Form.Group>

      <Button type="submit" className="btn btn-primary mr-2 position-relative">
        <Loader position={"right"} display={loading}></Loader>
        Add
      </Button>
    </Form>
  );
};

export default CampusClosedForm;
