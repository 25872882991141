import React, { useState, useCallback, useEffect } from "react";
import { RootStore } from "store";
import { Link } from "react-router-dom";
import { ProductForm, ConfirmModal } from "components";
import { observer } from "mobx-react-lite";
import { ProductSnapshot } from "store/models/Product";
import { productQueries } from "queries";
import { api } from "services";
import Product, { ProductModel } from "store/models/Product";

const ProductDetailPage = ({ history, match }) => {
  const [product, setProduct] = useState<ProductModel | undefined>();

  const getProduct = useCallback(async () => {
    const response = await api.sendQuery(
      productQueries.single(match.params.productId)
    );

    if (response.data.data)
      setProduct(Product.create(response.data.data.product));
  }, [match.params.productId]);

  useEffect(() => {
    if (!product) {
      if (RootStore.ProductStore && RootStore.ProductStore.products) {
        setProduct(
          RootStore.ProductStore?.products.find(
            (product) => product.id === match.params.productId
          )
        );
      } else {
        getProduct();
      }
    }
  }, [getProduct, product, match.params.productId]);

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const onSubmit = async (
    productData: ProductSnapshot,
    categories: number[],
    imageFile: any
  ) => {
    await product?.update(productData, categories, imageFile);
  };

  return (
    <>
      <div>
        <div className="page-header">
          <h3 className="page-title">{product?.name}</h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/products">Products</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {product?.name}
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12">
            <ProductForm
              onSubmitCallback={onSubmit}
              product={product}
              onRemoveClick={() => {
                setDeleteModalVisible(true);
              }}
            ></ProductForm>
          </div>
        </div>
      </div>

      <ConfirmModal
        show={deleteModalVisible}
        onHide={() => setDeleteModalVisible(false)}
        onConfirm={async () => {
          if (product && product.id) {
            const result = await RootStore.ProductStore?.removeProduct(
              product.id
            );

            setDeleteModalVisible(false);

            if (result) return history.push("/products");
          }
        }}
        modalText="Are you sure you want to archive this product?"
        modalTitle={"Archive " + product?.name}
      />
    </>
  );
};

export default observer(ProductDetailPage);
