import storeQueries from "./storeQueries";
import campusClosedQueries from "./campusClosedQueries";

const fields = `
  id
  name
  image
  address
`;

const fieldsWithChildren = `
  ${fields}
  stores {
    ${storeQueries.fieldsWithChildren}
  }  
  closed {
    ${campusClosedQueries.fields}
  }
`;

const populateFields = (campus) => {
  return `
    name: "${campus.name}"
    address: "${campus.address}" 
  `;
};

const list = () => ({
  query: `
    {
      campus {
        ${fieldsWithChildren}
      }
    }
    `,
});

const create = (campus) => ({
  query: `    
    mutation {
      createCampus(
        input: {
          ${populateFields(campus)}
        }
      ) {
        ${fieldsWithChildren}
      }
    }    
  `,
});

const createWithImage = (campus) => ({
  query: `    
  mutation ($file: Upload!) {
    createCampus(
      input: {
        ${populateFields(campus)}
        image: $file
      }
    ) {
      ${fieldsWithChildren}
    }
  }    
  `,
});

const update = (campus) => ({
  query: `
    mutation {
      updateCampus(
        id: ${campus.id}
        input: {
          ${populateFields(campus)}
        }
      ) {
        ${fields}
      }
    }    
  `,
});

const updateWithImage = (campus) => ({
  query: `
    mutation ($file: Upload!) {
      updateCampus(
        id: ${campus.id}
        input: {
          ${populateFields(campus)}
          image: $file
        }
      ) {
        ${fields}
      }
    }
  `,
});

const remove = (id) => ({
  query: `
    mutation {
      deleteCampus (
          id: ${id}
      ) {
          id
      }
    }
  `,
});

export default {
  list,
  create,
  createWithImage,
  update,
  updateWithImage,
  remove,
  fieldsWithChildren,
};
