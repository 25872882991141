import { types, Instance, SnapshotIn } from "mobx-state-tree";
import TimeSlotDays from "./TimeSlotDays";

const TimeSlot = types
  .model("TimeSlot", {
    id: types.identifier,
    from: types.string,
    to: types.string,
    active: types.boolean,
    day: types.maybe(types.integer),
    location_id: types.maybeNull(types.string),
  })
  .views((self) => ({
    get Day() {
      return TimeSlotDays[self.day ? self.day : ""];
    },
  }));

export type TimeSlotSnapshot = SnapshotIn<typeof TimeSlot>;
export type TimeSlotModel = Instance<typeof TimeSlot>;
export default TimeSlot;
