const fields = `
  id
  name
  description
  image
  price
  hasSpoon
  cartken
  demand
  weight
  categories {
    id
    name
  }
  stores {
    id
    name
    pivot {
      inStock
    }
  }
  created_at
  updated_at
  deleted_at
`;

const populateFields = (product) => {
  return `
    name: "${product.name}"
    description: "${product.description}"
    price: ${product.price}
    hasSpoon: ${product.hasSpoon}
    cartken: ${product.cartken}
  `;
};

const all = () => ({
  query: `
    {
      products {
        ${fields}
      }
    }
  `,
});

const single = (id) => ({
  query: `
  {
    product(id: ${id}) {
      ${fields}
    }
  }
  `,
});

const create = (product, categories) => ({
  query: `    
    mutation {
      createProduct(
        input: {
          ${populateFields(product)}
          categories: {
            sync: ${JSON.stringify(categories)}
          }
        }
        ) {
          ${fields}
        }
      }    
      `,
});

const createWithImage = (product, categories) => ({
  query: `    
    mutation ($file: Upload!) {
      createProduct(
        input: {
          ${populateFields(product)}
          categories: {
            sync: ${JSON.stringify(categories)}
          }
          image: $file
        }
        ) {
          ${fields}
        }
      }    
      `,
});

const update = (product, categories) => ({
  query: `    
    mutation {
      updateProduct(
        id: ${product.id}
        input: {
          ${populateFields(product)}
          categories: {
            sync: ${JSON.stringify(categories)}
          }
        }
      ) {
        ${fields}
      }
    }    
  `,
});

const updateWeight = (productId, weight) => ({
  query: `    
   mutation {
    updateWeight (
        id: ${productId}
        weight: ${weight}
    ) {
        id
        name
        weight
    }
    }   
  `,
});

const updateWithImage = (product, categories) => ({
  query: `
    mutation ($file: Upload!) {
      updateProduct(
        id: ${product.id}
        input: {
          ${populateFields(product)}
          categories: {
            sync: ${JSON.stringify(categories)}
          }
          image: $file
        }
      ) {
        ${fields}
      }
    }
  `,
});

const updateStock = (productId, stores) => {
  let storesQuery = ``;

  stores.forEach((store) => {
    storesQuery += `{id: ${store.id}, inStock: ${store.inStock}}`;
  });

  return {
    query: `    
    mutation {
      updateProduct(
        id: ${productId}
        input: {
          stores: {
            sync: [${storesQuery}]
          }
        }
      ) {
        ${fields}
      }
    }    
  `,
  };
};

const remove = (id) => ({
  query: `
    mutation {
      deleteProduct (
          id: ${id}
      ) {
          id
      }
    }
  `,
});

export default {
  all,
  create,
  createWithImage,
  update,
  updateWithImage,
  updateStock,
  updateWeight,
  remove,
  single,
  fields,
};
