import { types, applySnapshot } from "mobx-state-tree";
import Order, { OrderModel } from "./models/Order";
import PaginatorInfo, { PaginatorInfoSnapshot } from "./models/PaginatorInfo";

const OrderStore = types
  .model({
    orders: types.array(Order),
    paginatorInfo: PaginatorInfo,
  })
  .actions((self) => ({
    setPaginatorInfo: (paginatorInfo: PaginatorInfoSnapshot) => {
      applySnapshot(self.paginatorInfo, paginatorInfo);
    },
  }))
  .views((self) => ({
    get TableData() {
      return self.orders.map((order: OrderModel, i) => {
        return order.TableData;
      });
    },

    get SplitTableData() {
      if (self.orders.length <= 0) return [];

      return self.orders.reduce((result, order) => {
        const timeSlotId = order.timeslot.id;
        const locationId = order.location.id;

        if (!result[timeSlotId]) result[timeSlotId] = [];
        if (!result[timeSlotId][locationId])
          result[timeSlotId][locationId] = [];

        result[timeSlotId][locationId].push(order.TableData);
        return result;
      }, []) as any;
    },
  }));

export default OrderStore;
