const fields = `
  id
  date
`;

const create = (campusClosed) => ({
  query: `    
    mutation {
      updateCampus(
        id: ${campusClosed.campus_id}
        input: {
          closed: {
            create: [
              {
                date: "${campusClosed.date}"
              }
            ]
          }
        }
        ) {
          closed {
            ${fields}
          }
        }
      }    
      `,
});

const remove = (id, campusId) => ({
  query: `
    mutation {
      updateCampus (
          id: ${campusId}
          input: {
            closed: {
              delete: [${id}]
            }
          }
      ) {
          id
      }
    }
  `,
});

export default {
  create,
  remove,
  fields,
};
