import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import OrderStatuses from "store/models/OrderStatuses";
import moment from "moment";

const OrderDetailModal = ({
  order,
  printMode = false,
}: {
  order: any;
  printMode: boolean;
}) => {
  const [updating, setUpdating] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(order.rawStatus);

  return order ? (
    <div
      className="receipt-print-wrapper"
      style={printMode ? { margin: "100px", width: "500px" } : {}}
    >
      <div className="col-lg-12 border-bottom">
        <div className="wrapper pt-2 pb-3">
          <div className="col-md mb-2 d-flex">
            <div className="d-inline-flex align-items-center justify-content-left">
              <i className="mdi mdi-key-variant"></i>
            </div>
            <div className="wrapper pl-3">
              <h5 className="font-weight-medium mb-0">order id</h5>
              <p className="mb-0 font-weight-medium text-muted">#{order.id}</p>
            </div>
          </div>
        </div>
      </div>

      {order.pickUpPinCode != 0 ? (
        <div className="col-lg-12 border-bottom">
          <div className="wrapper pt-2 pb-3">
            <div className="col-md mb-2 d-flex">
              <div className="d-inline-flex align-items-center justify-content-left">
                <i className="mdi mdi-lock"></i>
              </div>
              <div className="wrapper pl-3">
                <h5 className="font-weight-medium mb-0">pickup code</h5>
                <p className="mb-0 font-weight-medium text-muted">
                  {order.pickUpPinCode}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="col-lg-12 border-bottom">
        <div className="wrapper pt-4 pb-3">
          <div className="col-md mb-2 d-flex">
            <div className="d-inline-flex align-items-center justify-content-left">
              <i className="mdi mdi-account"></i>
            </div>
            <div className="wrapper pl-3">
              <h5 className="font-weight-medium mb-0">user</h5>
              <p className="mb-0 font-weight-medium text-muted">
                {order.email}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-12 border-bottom">
        <div className="wrapper pt-4 pb-3">
          <div className="col-md mb-2 d-flex">
            <div className="d-inline-flex align-items-center justify-content-left">
              <i className="mdi mdi-check"></i>
            </div>
            <div className="wrapper pl-3 w-100">
              <h5 className="font-weight-medium mb-0">details</h5>
              {order.log.map((log, i) => (
                <p key={i} className="mb-0 font-weight-medium text-muted">
                  {moment(new Date(log.created_at)).format("YYYY-MM-DD HH:mm")}{" "}
                  {log.msg}
                </p>
              ))}

              {!printMode ? (
                <div className="d-flex mt-3">
                  <Select
                    className="justify-content-left w-100 mr-2"
                    options={Object.keys(OrderStatuses).map((key) => {
                      return {
                        value: key,
                        label: OrderStatuses[key],
                      };
                    })}
                    onChange={(selectedStatus) => {
                      setSelectedStatus(selectedStatus.value);
                    }}
                    defaultValue={{
                      value: selectedStatus,
                      label: OrderStatuses[selectedStatus],
                    }}
                  />
                  <Button
                    className="justify-content-end"
                    onClick={async () => {
                      setUpdating(true);
                      order.setStatus(selectedStatus);
                      await order.update();
                      setUpdating(false);
                    }}
                    disabled={updating}
                  >
                    Save
                  </Button>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-12 border-bottom">
        <div className="wrapper pt-4 pb-3">
          <div className="col-md mb-2 d-flex">
            <div className="d-inline-flex align-items-center justify-content-left">
              <i className="mdi mdi-cart"></i>
            </div>
            <div className="wrapper pl-3 w-100">
              <h5 className="font-weight-medium mb-0">order</h5>
              <p className="mb-0 font-weight-medium text-muted">
                {order.totalItems} items
              </p>
            </div>
            {!printMode ? (
              <div className="d-flex align-items-center justify-content-end">
                <Link
                  to={"/orders/" + order.id + "/print"}
                  target="_blank"
                  className="btn btn-primary"
                >
                  Print receipt
                </Link>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="col-lg-12 border-bottom">
        <div className="wrapper pt-4 pb-3">
          <div className="col-md mb-2 d-flex">
            <div className="d-inline-flex align-items-center justify-content-left">
              <i className="mdi mdi-map-marker"></i>
            </div>
            <div className="wrapper pl-3">
              <h5 className="font-weight-medium mb-0">
                {order.isDeliveryOrder ? "delivery" : "pick-up"} point
              </h5>
              <p className="mb-0 font-weight-medium text-muted">
                {order.locationName}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-12">
        <div className="wrapper pt-4 pb-3">
          <div className="col-md mb-2 d-flex">
            <div className="d-inline-flex align-items-center justify-content-left">
              <i className="mdi mdi-map-marker"></i>
            </div>
            <div className="wrapper pl-3">
              <h5 className="font-weight-medium mb-0">timeslot</h5>
              <p className="mb-0 font-weight-medium text-muted">
                {order.timeslot.from} - {order.timeslot.to}
              </p>
            </div>
          </div>
        </div>
      </div>

      {order.items.length > 0 ? (
        <>
          <hr color="black"></hr>
          {order.items.map((item, i) => (
            <div
              className={
                "col-lg-12 " +
                (i < order.items.length - 1 ? "border-bottom" : "")
              }
              key={i}
            >
              <div className="wrapper pt-4 pb-3">
                <div className="col-md d-flex">
                  <div className="wrapper pl-3 w-100">
                    <h5 className="font-weight-medium mb-0">
                      {item.product.name}
                    </h5>
                    <p className="mb-0 font-weight-medium text-muted">
                      {item.product.description}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="d-flex flex-column  text-right">
                      <p className="text-danger m-0">{item.quantity}x</p>
                      <span className="m-0 p-0">
                        €{item.product.price.toFixed(2)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        ""
      )}

      <hr color="black"></hr>

      <div className="col-lg-12">
        <div className="wrapper pt-4 pb-3">
          <div className="col-md mb-2 d-flex">
            <div className="wrapper pl-3 w-100">
              {order.isDeliveryOrder ? (
                <h5 className="font-weight-medium mb-0">
                  delivery <span className="text-muted">free from €10.00</span>
                </h5>
              ) : (
                <></>
              )}
              <h5 className="font-weight-medium mb-0">total</h5>
            </div>
            <div className="d-flex align-items-center justify-content-end text-right">
              <div className="d-flex flex-column">
                {order.isDeliveryOrder ? (
                  <h5 className="font-weight-bold m-0">
                    €{Number(order.deliveryPrice).toFixed(2)}
                  </h5>
                ) : (
                  <></>
                )}
                <h5 className="font-weight-bold m-0">
                  €{Number(order.totalPriceWithDelivery).toFixed(2)}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default observer(OrderDetailModal);
