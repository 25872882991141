import React, { useState } from "react";
import { Form, Button, InputGroup } from "react-bootstrap";
import { ProductModel, ProductSnapshot } from "store/models/Product";
import storageUtil from "util/storageUtil";
import Loader from "components/Loader";

const ProductForm = ({
  onSubmitCallback,
  product = undefined,
  onRemoveClick = undefined,
}: {
  onSubmitCallback: (
    productData: ProductSnapshot,
    categories: number[],
    imageFile: any
  ) => Promise<void>;
  product?: ProductModel;
  onRemoveClick?: () => void;
}) => {
  const [productFile, setProductFile] = useState<any>();
  const [loading, setLoading] = useState(false);

  const onFileChange = (event) => {
    setProductFile(event.target.files[0]);
  };

  const onSubmit = async (event) => {
    setLoading(true);

    event.preventDefault();

    const productData = {
      id: product ? product.id : "",
      name: event.target[0].value,
      description: event.target[1].value,
      price: event.target[6].value,
      hasSpoon: event.target[7].checked,
      cartken: event.target[8].checked
    };

    const categories: number[] = [];
    if (event.target[3].checked) categories.push(1);
    if (event.target[4].checked) categories.push(2);
    if (event.target[5].checked) categories.push(3);

    const image = event.target[2].files[0];

    await onSubmitCallback(productData, categories, image);

    setLoading(false);
  };

  return (
    <Form onSubmit={onSubmit}>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-12">
                    <h4 className="card-title">Product details</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Group>
                      <label htmlFor="name">Name</label>
                      <Form.Control
                        type="text"
                        id="name"
                        placeholder="Name"
                        defaultValue={product?.name}
                        required
                      />
                    </Form.Group>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <Form.Group>
                      <label htmlFor="description">Description</label>
                      <Form.Control
                        type="text"
                        id="description"
                        placeholder="Description"
                        defaultValue={product?.description?.toString()}
                        required
                      />
                    </Form.Group>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <Form.Group>
                      <label>Image</label>
                      <div className="custom-file">
                        <Form.Control
                          type="file"
                          className="form-control visibility-hidden"
                          id="locationImage"
                          onChange={onFileChange}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="locationImage"
                        >
                          {productFile
                            ? productFile.name
                            : product?.image
                            ? storageUtil.getFileName(product.image)
                            : "Choose image"}
                        </label>
                      </div>
                      {productFile ? (
                        <img
                          className="img-lg mt-2"
                          alt="Store"
                          src={URL.createObjectURL(productFile)}
                        ></img>
                      ) : product?.image ? (
                        <img
                          className="img-lg mt-2"
                          alt="Store"
                          src={storageUtil.getStoragePath(product.image)}
                        ></img>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-12">
                    <h4 className="card-title">Options</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Group>
                      <label htmlFor="categories">Categories</label>
                      <Form.Check
                        type="checkbox"
                        id={`category-food`}
                        label={`Food`}
                        defaultChecked={product?.isFood}
                      />
                      <Form.Check
                        type="checkbox"
                        id={`category-snacks`}
                        label={`Snacks`}
                        defaultChecked={product?.isSnack}
                      />
                      <Form.Check
                        type="checkbox"
                        id={`category-drinks`}
                        label={`Drinks`}
                        defaultChecked={product?.isDrink}
                      />
                    </Form.Group>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <Form.Group>
                      <label htmlFor="email">Price</label>
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text>€</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          type="number"
                          step="0.01"
                          min="0"
                          placeholder="Price"
                          aria-label="Price"
                          required
                          defaultValue={product?.price}
                        />
                      </InputGroup>
                    </Form.Group>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <Form.Group>
                      <label htmlFor="type">Spoon?</label>
                      <Form.Check
                        type="switch"
                        id="hasSpoon"
                        label="Add spoon?"
                        defaultChecked={product?.hasSpoon}
                      />
                    </Form.Group>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <Form.Group>
                      <label htmlFor="type">Fits in cartken?</label>
                      <Form.Check
                          type="switch"
                          id="cartken"
                          label="fits in cartken?"
                          defaultChecked={product?.cartken}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <Button
                  type="submit"
                  className="btn btn-primary mr-2 position-relative"
                >
                  <Loader
                    position={product ? "left" : "right"}
                    display={loading}
                  ></Loader>
                  {product ? "Save" : "Add"}
                </Button>

                {product ? (
                  <Button variant="warning" onClick={onRemoveClick}>
                    Archive
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default ProductForm;
