import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import storageUtil from "util/storageUtil";
import { observer } from "mobx-react-lite";
import { CampusModel, CampusSnapshot } from "store/models/Campus";
import { RootStore } from "store";
import Loader from "components/Loader";

const CampusForm = ({
  onSubmitCallback,
  campus = undefined,
  onRemoveClick = undefined,
}: {
  onSubmitCallback: (campus: CampusSnapshot, imageFile) => Promise<void>;
  campus?: CampusModel;
  onRemoveClick?: () => void;
}) => {
  const [file, setFile] = useState<any>();
  const [loading, setLoading] = useState(false);

  const onFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const onSubmit = async (event) => {
    setLoading(true);

    event.preventDefault();

    const campusData: CampusSnapshot = {
      id: campus && campus.id ? campus.id : "",
      name: event.target[1].value,
      address: event.target[2].value,
    };

    const image = event.target[3].files[0];

    await onSubmitCallback(campusData, image);

    setLoading(false);
  };

  return (
    <Form onSubmit={onSubmit}>
      <fieldset disabled={!RootStore.User?.isAdmin}>
        <Form.Group>
          <label htmlFor="name">Name</label>
          <Form.Control
            type="text"
            id="name"
            placeholder="Name"
            defaultValue={campus?.name}
            required
          />
        </Form.Group>

        <Form.Group>
          <label htmlFor="address">Address</label>
          <Form.Control
            type="text"
            id="address"
            placeholder="Address"
            defaultValue={campus?.address?.toString()}
            required
          />
        </Form.Group>

        <Form.Group>
          <label>Image</label>
          <div className="custom-file">
            <Form.Control
              type="file"
              className="form-control visibility-hidden"
              id="image"
              onChange={onFileChange}
            />
            <label className="custom-file-label" htmlFor="image">
              {file
                ? file.name
                : campus?.image
                ? storageUtil.getFileName(campus.image)
                : "Choose image"}
            </label>
          </div>
          {file ? (
            <img
              className="img-lg mt-2"
              alt="Campus"
              src={URL.createObjectURL(file)}
            ></img>
          ) : campus?.image ? (
            <img
              className="img-lg mt-2"
              alt="Campus"
              src={storageUtil.getStoragePath(campus.image)}
            ></img>
          ) : (
            ""
          )}
        </Form.Group>

        <Button
          type="submit"
          className="btn btn-primary mr-2 position-relative"
        >
          <Loader
            position={campus ? "left" : "right"}
            display={loading}
          ></Loader>

          {campus ? "Save" : "Add"}
        </Button>

        {/* {campus ? (
          <Button variant="danger" onClick={onRemoveClick}>
            Remove
          </Button>
        ) : (
          ""
        )} */}
      </fieldset>
    </Form>
  );
};

export default observer(CampusForm);
