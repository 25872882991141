import { types, Instance, SnapshotIn, flow } from "mobx-state-tree";
import UserRole from "./UserRole";
import { userQueries } from "queries";
import { api } from "services";
import Campus from "./Campus";
import alertUtil from "util/alertUtil";

const rolesPerView = {
  admin: ["dashboard", "orders", "products", "users", "campuses"],
  picker: ["picker", "campuses"],
  delivery: ["delivery"],
};

const User = types
  .model("User", {
    id: types.identifier,
    email: types.string,
    password: types.maybeNull(types.string),
    roles: types.array(UserRole),
    campus: types.maybeNull(types.reference(Campus)),
    campus_id: types.maybeNull(types.string),
  })
  .actions((self) => ({
    hasAccessTo: (name) => {
      return self.roles.some((role) => rolesPerView[role.name].includes(name));
    },
    update: flow(function* (user: any, roles: number[]) {
      const response = yield api.sendQuery(userQueries.update(user, roles));

      if (response.data.data && response.data.data.updateUser) {
        const data = response.data.data.updateUser;
        self.roles = data.roles;

        if (data.campus) {
          self.campus = data.campus.id;
        } else {
          self.campus = null;
        }
        alertUtil.success("User updated");
      }
    }),
  }))
  .views((self) => ({
    get Role() {
      return self.roles
        .map(
          ({ name }) => name.substring(0, 1).toUpperCase() + name.substring(1)
        )
        .join(", ");
    },

    get isAdmin() {
      return self.roles.some((role) => role.id === "1");
    },

    get isPicker() {
      return self.roles.some((role) => role.id === "2");
    },

    get isDelivery() {
      return self.roles.some((role) => role.id === "3");
    },
  }));

export type UserSnapshot = SnapshotIn<typeof User>;
export type UserModel = Instance<typeof User>;

export default User;
