import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { TimeSlotSnapshot } from "store/models/TimeSlot";
import moment from "moment";
import Loader from "components/Loader";

const TimeSlotForm = ({
  onSubmitCallback,
}: {
  onSubmitCallback: (timeSlotData: TimeSlotSnapshot) => Promise<void>;
}) => {
  const getDefaultTime = (): Date => {
    const date = new Date();
    date.setMinutes(date.getMinutes() > 30 ? 30 : 0);
    return date;
  };

  const getDefaultToTime = (): Date => {
    const date = moment(getDefaultTime()).add("30", "m").toDate();
    return date;
  };

  const [loading, setLoading] = useState(false);
  const [selectedFromDate, setSelectedFromDate] = useState(getDefaultTime);
  const [selectedToDate, setSelectedToDate] = useState(getDefaultToTime);

  const onSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    const timeSlotData: TimeSlotSnapshot = {
      id: "",
      from: event.target[0].value,
      to: event.target[1].value,
      active: true,
    };

    await onSubmitCallback(timeSlotData);
    setLoading(false);
  };

  return (
    <Form onSubmit={onSubmit}>
      <Form.Group>
        <label htmlFor="name">From</label>
        <div className="col-md-12">
          <DatePicker
            className="form-control"
            selected={selectedFromDate}
            onChange={(date: Date) => setSelectedFromDate(date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="HH:mm"
            timeFormat="HH:mm"
            required
          />
        </div>
      </Form.Group>

      <Form.Group>
        <label htmlFor="name">To</label>
        <div className="col-md-12">
          <DatePicker
            className="form-control"
            selected={selectedToDate}
            onChange={(date: Date) => setSelectedToDate(date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="HH:mm"
            timeFormat="HH:mm"
            required
          />
        </div>
      </Form.Group>

      <Button type="submit" className="btn btn-primary mr-2 position-relative">
        <Loader position="right" display={loading}></Loader>
        Add
      </Button>
    </Form>
  );
};

export default TimeSlotForm;
