import React from "react";
import ReactDOM from "react-dom";

import * as Sentry from "@sentry/browser";

import App from "./App";

if (process.env.NODE_ENV === "production")
  Sentry.init({
    dsn:
      "https://5d0239aa432c4e4a89fc73b5eef41f4c@o178697.ingest.sentry.io/5286301",
  });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
