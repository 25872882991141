import { types, SnapshotIn, Instance } from "mobx-state-tree";

const PaginatorInfo = types
  .model("PaginatorInfo", {
    currentPage: 1,
    perPage: 10,
    lastPage: 1,
  })
  .actions((self) => ({
    setCurrentPage: (page) => {
      self.currentPage = page;
    },
    setPerPage: (perPage) => {
      self.perPage = perPage;
    },
  }));

export type PaginatorInfoSnapshot = SnapshotIn<typeof PaginatorInfo>;
export type PaginatorInfoModel = Instance<typeof PaginatorInfo>;
export default PaginatorInfo;
