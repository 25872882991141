import React, { useState } from "react";
import { api } from "services";
import { Form } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { authQueries } from "queries";
import alertUtil from "util/alertUtil";
import { useHistory } from "react-router";
import QueryString from "query-string";

const ResetPassword = ({ location }) => {
  const history = useHistory();
  const query = QueryString.parse(location.search);
  const token = query.token;

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const submittable = password !== confirmPassword || password === "";

  const handleSubmit = async (event) => {
    if (event.currentTarget.checkValidity()) {
      event.preventDefault();

      if (
        password.length > 0 &&
        confirmPassword.length > 0 &&
        password === confirmPassword
      ) {
        const response = await api.sendQuery(
          authQueries.reset(password, token)
        );

        if (response.data.data) {
          if (response.data.data.reset) {
            alertUtil.success("Your password has been reset");
            return history.push("/login");
          } else {
            alertUtil.error("Your password could not be reset");
          }
        }
      }
    }
  };

  return (
    <div>
      <div className="d-flex align-items-center auth px-0">
        <div className="row w-100 mx-0">
          <div className="col-xl-4 col-md-8 mx-auto">
            <div className="auth-form-light text-left py-5 px-4 px-sm-5">
              <div className="brand-logo">
                <img src={require("../../assets/images/logo.png")} alt="logo" />
              </div>
              <h4>SPAR Delivery</h4>
              <h6 className="font-weight-light">Reset your password</h6>
              <Form className="pt-3" onSubmit={handleSubmit}>
                <Form.Group className="d-flex">
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    size="lg"
                    className="h-auto"
                    required
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </Form.Group>
                <Form.Group className="d-flex mb-3">
                  <Form.Control
                    type="password"
                    placeholder="Confirm password"
                    size="lg"
                    className="h-auto"
                    required
                    onChange={(event) => setConfirmPassword(event.target.value)}
                  />
                </Form.Group>

                <p className="text-danger mt-0">
                  {password.length > 0 &&
                  confirmPassword.length > 0 &&
                  password !== confirmPassword
                    ? "The entered passwords don't match"
                    : ""}
                </p>

                <div className="mt-3">
                  <button
                    disabled={submittable}
                    className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                  >
                    SUBMIT
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(ResetPassword);
