import { types } from "mobx-state-tree";
import { flow } from "mobx";
import { api } from "services";
import { campusQueries } from "queries";
import { AxiosResponse } from "axios";
import Campus, { CampusModel, CampusSnapshot } from "./models/Campus";
import alertUtil from "util/alertUtil";

const CampusStore = types
  .model({
    campuses: types.array(Campus),
  })
  .actions((self) => ({
    add(campus: CampusSnapshot) {
      self.campuses = [...self.campuses, campus] as any;
    },

    delete(id: string) {
      const campus = self.campuses.find(
        (campus) => campus.id === id
      ) as CampusModel;
      self.campuses.remove(campus);
    },
  }))
  .actions((self) => ({
    create: flow(function* (campus: CampusSnapshot, file: any = undefined) {
      let query, response: AxiosResponse;

      if (file) {
        query = campusQueries.createWithImage(campus);
        response = yield api.sendQueryWithFile(query, file);
      } else {
        query = campusQueries.create(campus);
        response = yield api.sendQuery(query);
      }

      if (response.data.data && response.data.data.createCampus) {
        self.add(response.data.data.createCampus);
        alertUtil.success("Campus created");
      }
    }),

    remove: flow(function* (id: string) {
      const response = yield api.sendQuery(campusQueries.remove(id));

      if (response.data.data && response.data.data.deleteCampus) {
        self.delete(id);
        alertUtil.success("Campus deleted");
        return true;
      }

      return false;
    }),
  }));

export default CampusStore;
